// BoardMain.js
// import 'context-filter-polyfill';
import './BoardMain.css'; // Стили для StartScreen
import React, { useEffect, } from 'react';

import ConvyBoard from './ConvyBoard';
import SpectatorBoard from './SpectatorBoard';
import Spinner from './spinner/Spinner';
import ResultsOverlay from './results/ResultsOverlay';
import GameNotLoaded from './results/GameNotLoaded';
import SettingsOverlay from './helpers/SettingsOverlay';
import WordChangeOverlay from './helpers/WordChangeOverlay';
import BrushMenu from './helpers/BrushMenu';
import ModeInfoOverlay from './helpers/ModeInfoOverlay';
import PremiumNew from './premium/PremiumNew';
import PremiumInfo from './premium/PremiumInfo';

import { useGameContext } from '../../contexts/GameContext';
import { useBrushContext } from '../../contexts/BrushContext';
import { useDrawingContext } from '../../contexts/DrawingContext';
import RicRoll from './rickroll/RicRoll';

function BoardMain() {

  const gameContext = useGameContext();
  const brushContext = useBrushContext();
  const drawingContext = useDrawingContext();
  
  const { 
    gameInfo,
    strokesAreLoaded,
    gameInfoRef,
    gameError,
    canDraw,
    continueDrawing, setContinueDrawing,
    showSettings, 
    showBrushMenu,
    showWordSettings, 
    showPremiumMenu, 
    showPremiumInfo, 
    showModeHelp,

    reloadGame,
    isChecked,
    isHacker,

    userSetsRef,
   } = gameContext;

  const showResultsOverlay = gameInfoRef.current?.results && !continueDrawing;

  return (
    <>
      {!strokesAreLoaded && !isHacker && (
        <Spinner />
      )}
      {isChecked && isHacker && (
        <RicRoll />
      )}
      {showSettings && (
        <SettingsOverlay 
          gameContext={gameContext}
          brushContext={brushContext}
        />
      )}
      {gameInfoRef.current?.modeHelp && showModeHelp && !gameInfoRef.current?.results && (
        <ModeInfoOverlay 
          gameContext={gameContext}
        />
      )}
      {showBrushMenu && (
        <BrushMenu 
          gameContext={gameContext}
          brushContext={brushContext}
          drawingContext={drawingContext}
        />
      )}
      {showWordSettings && (
        <WordChangeOverlay 
          gameContext={gameContext}
        />
      )}
      {showPremiumMenu && (
        <PremiumNew 
          gameContext={gameContext}
          brushContext={brushContext}
        />
      )}
      {showPremiumInfo && (
        <PremiumInfo 
          gameContext={gameContext}
        />
      )}
      {showResultsOverlay && (
        <ResultsOverlay 
          game={gameInfoRef.current}
          canDraw={canDraw}
          setContinueDrawing={setContinueDrawing}
        />
      )}
      {gameError?.error && (
        <GameNotLoaded
          gameError={gameError}
          reloadGame={reloadGame}
        />
      )}

      <div
        style = {{
          opacity: (strokesAreLoaded || gameError) ? 1 : 0,
        }}
      >
        { 
          canDraw ? 
          (
            // <DrawingBoard />
            <ConvyBoard />
          ): (
          <SpectatorBoard />
          )
        }
      </div>
      
    </>
  );
}

export default BoardMain;