// BrushSizeIndicator
import React from 'react';

const BrushSizeIndicator = ({ 
  canvasContext,
  brushContext,
  color, 
  position, 
  clickPosition, 
  visibleLineWidth, 
  brushIndicatorFrame, 
  softness,
 }) => {
    if (!position || !visibleLineWidth) {
        return null; // Не отображаем компонент, если позиция или размер кисти не заданы
    }

    const {
      zoomFactor,
      controlPanelOffsetRef,
    } = canvasContext;

    // Вычисляем смещение для центрирования по горизонтали и позиционирования дна кружка на указателе
    const horizontalOffset = -visibleLineWidth / 2;
    const verticalOffset = -visibleLineWidth - 60;

    const blurSize = `${softness * visibleLineWidth}px`; // Пример коэффициента умножения на 3

    const maxBottomPosition = (controlPanelOffsetRef.current || 430) + 45

    const brushSizeText = brushContext.lineWidth.toFixed(Math.min(1, Math.floor(zoomFactor/5)));


    return (
        <div
            style={{
                position: 'fixed',
                left: `${position.x + horizontalOffset}px`, // Центрирование по горизонтали
                top: `${Math.min(maxBottomPosition, position.y) + verticalOffset}px`, // Позиционирование дна кружка на указателе
                width: `${visibleLineWidth}px`,
                height: `${visibleLineWidth}px`,
                borderRadius: '50%', // Делаем круг
                background: color,
                pointerEvents: 'none', // Исключает элемент из событий указателя
                display: 'flex',
                alignItems: 'flex-end', // Выравниваем содержимое (внутренний круг) по нижнему краю
                justifyContent: 'left', // и по центру по горизонтали
                boxShadow: softness ? 0 : brushIndicatorFrame,
                filter: `blur(${blurSize})`, 
                zIndex: 200,
            }}
        >
          <div style={{
                zIndex: 55, 
                color: 'grey',
                fontSize: '14px',
                opacity: 0.8,
                transform: `translate(${3 + visibleLineWidth}px, ${8 -visibleLineWidth/2}px)`
            }}>
            {brushSizeText}px
          </div>
        </div>
    );
};

export default BrushSizeIndicator;
