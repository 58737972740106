import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGameContext } from '../../contexts/GameContext';
import { useDrawingContext } from '../../contexts/DrawingContext';
import { useHistoryContext  } from '../../contexts/HistoryContext';
import { useBrushContext  } from '../../contexts/BrushContext';

export const useHistory = () => {

  const { t } = useTranslation();

  const gameContext = useGameContext();
  const canvasContext = useDrawingContext();
  const historyContext = useHistoryContext();
  const brushContext = useBrushContext();

  const {
    gameInfoRef,
    socketRef,
    gameId,
    groupId,
    saveDrawing,
    activeUserIdRef,
    moderatorIdRef,
    connectionStatusRef,
  } = gameContext;

  const {
    canvasSideRef,
    canvasDimensionsRef,

  } = canvasContext;

  const {
    userStrokesRef,
    setRedrawer,
  } = historyContext;

  const {
    lastColorsRef,
  } = brushContext;

  const undoLastAction = useCallback(() => {

    let myStrokes = userStrokesRef.current[activeUserIdRef.current];
    if (!myStrokes) { return; }

    const myHistory = myStrokes.filter(stroke=> !stroke.cancelled);
    const lastStroke = myHistory.pop();
  
    // Если такой мазок найден, удаляем его из истории
    if (lastStroke) {

      if (gameInfoRef.current.pngTime?.[lastStroke.time]) {return;}

      lastStroke.cancelled = Date.now();
      setRedrawer(i => i + 1);
      saveHistory ()
    }

  }, []); // Обновляем зависимости useCallback

  const redoLastAction = useCallback(() => {

    // window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')

    let myStrokes = userStrokesRef.current[activeUserIdRef.current];
    if (!myStrokes) { return; }

    const myHistory = myStrokes.filter(stroke=> stroke.cancelled)
    .sort((a, b)=> b.cancelled - a.cancelled);

    const firstStroke = myHistory.shift();
  
    // Если такой мазок найден, удаляем его из истории
    if (firstStroke) {
      delete firstStroke.cancelled;
      setRedrawer(i => i + 1);
      saveHistory ()
    }

  }, []); // Обновляем зависимости useCallback


  const confirmClearCanvas = () => {

    // window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('error')

    const confirm = true;

    // Проверка наличия объекта Telegram Web App
    if (confirm && window.Telegram?.WebApp?.showPopup) {
        // Параметры для попапа
        const popupParams = {
            title: t('clear.title'),
            message: t('clear.text'),
            buttons: [
              { id: 'clear', type: 'destructive', text: t('clear.button_confirm') },
              { id: 'cancel', type: 'cancel', text: t('clear.button_cancel') },
            ]
        };
        // Отображение попапа и обработка результата

        try {
          window.Telegram.WebApp.showPopup(popupParams, (buttonId) => {
            if (buttonId === 'clear') {
                clearCanvas();
            }
        });
        } catch (e) {
          clearCanvas();
        }

    } else {
        clearCanvas();
    }
  }

  const clearCanvas = () => {
    const clearStroke = {
        type: 'clear',
        time: Date.now(),
    };

    let myStrokes = userStrokesRef.current[activeUserIdRef.current];
    myStrokes.push(clearStroke);
    setRedrawer(i => i + 1);
    saveHistory ()
  };
  

  function saveHistory () {

    const myHistory = userStrokesRef.current[activeUserIdRef.current];
    const historyToSave = myHistory.filter(stroke=>!stroke.cancelled);

    saveDrawing({
      side: canvasSideRef.current,
      dimensions: canvasDimensionsRef.current,

      socket: socketRef.current, 
      colors: lastColorsRef.current,
      strokes: historyToSave, 
      gameId, 
      groupId, 
      userId: activeUserIdRef.current,
      moderId: moderatorIdRef.current,
      connectionStatusRef,
    }); 
    
  }


  return {
    undoLastAction,
    redoLastAction,
    confirmClearCanvas,
    clearCanvas,
    saveHistory,
  };
};

