import React from 'react';
import { ReactSVG } from "react-svg";
import "./CircleSwitch.css";

function CircleSwitch({
    id,
    className,
    text,

    path,
    icon,
    available = true,

    onClick,
    isActive = false,
    cursor = 'pointer',
    color,
    svgStyle = {},

    huawei,
}: any) {

    svgStyle = Object.assign({
      height: "25px", 
      width: "25px",
      color,
      // fill: isActive ? color : 'initial', // Управление заливкой цветом
    }, svgStyle);

    let buttonStyle = available ? 'circle-switch' : 'circle-switch-n-a'


    return (
        <div
            id={id}
            className={
              `${className || ""} ${buttonStyle} ${isActive ? "active" : ""}`
            }
            onClick={onClick}
            style={{cursor}}
        >

            { (huawei || !path) ? (
              icon ? (
                <span
                    className="svg"
                    style={svgStyle}
                >{React.cloneElement(icon, { style: svgStyle })}</span>
                ) : (
                    <div className="svg empty-circle" style={{ height: "25px", width: "25px", borderRadius: "50%" }}></div>
                )
              ) :
              path ? (
                <ReactSVG
                    className="svg"
                    src={path}
                    style={svgStyle}
                />
              ) : (
                  <div className="svg empty-circle" style={{ height: "25px", width: "25px", borderRadius: "50%" }}></div>
              )
            }
            {/* <span>{text}</span> */}
        </div>
    );
}

export default CircleSwitch;
