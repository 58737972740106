import React, { useState, useEffect } from 'react';

const ConvyBrushIndicator = ({
  canvasContext,
  brushContext,
}) => {

  const {
    canvasStyleDimensionsRef,
    canvasRef,
    isDrawingRef,
    mouseDetected,
    mousePositionRef,
    moveCanvas,

    panOffset,
    rotation,
  } = canvasContext;

  const {
    color,
    visibleLineWidth,
    softness,

    showPalette,
    isResizingBrush,
    isChangingBrightness,
    isChangingOpacity,
    isChangingSoftness,
    brushIndicatorFrame,
    activeTool,

    hotMouseStartPositionRef,
    hotMouseActive,

  } = brushContext;

  const [cursorPos, setCursorPos] = useState(positionInCanvas(mousePositionRef.current));
  const [isVisible, setIsVisible] = useState(false);
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0; // Проверяем, есть ли поддержка тач-интерфейса

  function positionInCanvas ({x, y}) {
    if (!canvasRef.current) {return {x: 0, y: 0}};
    const canvasRect = canvasRef.current.getBoundingClientRect();
    return {
      // x: x,
      // y: y,
      x: x - canvasRect.left,
      y: y - canvasRect.top,
    };
  }

  useEffect(() => {
    if (
      (isTouchDevice && !mouseDetected) 
      || showPalette
      || isResizingBrush
      || isChangingBrightness
      || isChangingOpacity
      || isChangingSoftness
      ) {
      setIsVisible(false);
      // Если устройство с тач-интерфейсом, не регистрируем обработчики
      return;
    } 

    const canvasRect = canvasRef.current.getBoundingClientRect();
    const fromOutsideWidth = 30

    const isInsideCanvas = (
      cursorPos.x >= - fromOutsideWidth 
      && cursorPos.x <= (canvasRect.right - canvasRect.left) + fromOutsideWidth 
      && cursorPos.y >= - fromOutsideWidth 
      && cursorPos.y <= (canvasRect.bottom - canvasRect.top) + fromOutsideWidth
    );

    if (isInsideCanvas) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  
  }, [cursorPos, canvasRef, isTouchDevice, mouseDetected, showPalette, isResizingBrush, activeTool, isChangingBrightness, isChangingOpacity, isChangingSoftness]); // Добавили isTouchDevice в зависимости
  
  
  useEffect(() => {

    const handleMouseMove = (event) => {
      if (!canvasRef.current) { return; }
      
      const canvasRect = canvasRef.current.getBoundingClientRect();
      const scaledPosition = hotMouseActive ? {
        x: (hotMouseStartPositionRef.current.x - canvasRect.left),
        y: (hotMouseStartPositionRef.current.y - canvasRect.top)
      } :
      {
        x: (event.clientX - canvasRect.left),
        y: (event.clientY - canvasRect.top)
      };
      setCursorPos(scaledPosition);
    };
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
    
  }, []); // Добавили isTouchDevice в зависимости

  const blurSize = `${softness * visibleLineWidth}px`; // Пример коэффициента умножения на 3

  if (!canvasRef.current) {return;}
  const canvasRect = canvasRef.current.getBoundingClientRect();

  return (isVisible) ? (
    <div className = 'temp_canvas' style = {{
      overflow: 'hidden',
      transform: `translate(${panOffset.x - canvasStyleDimensionsRef.current.width / 2}px, ${panOffset.y}px)`,
      width: canvasRect.width,
      height: canvasRect.height,
      zIndex: 120,
      pointerEvents: 'none',
    }}>
      <div
        style={{
          position: 'absolute',
          left: `${cursorPos.x - visibleLineWidth/2}px`,
          top: `${cursorPos.y - visibleLineWidth/2}px`,
          width: `${visibleLineWidth}px`,
          height: `${visibleLineWidth}px`,
          backgroundColor: isDrawingRef.current? '#00000000' : color,
          borderRadius: '50%',
          pointerEvents: 'none',
          cursor: 'none',
          boxShadow: softness ? 0 : brushIndicatorFrame,
          filter: `blur(${blurSize})`, 
          zIndex: 150,
        }}
      />
    </div>
  ) : null;
};

export default ConvyBrushIndicator;
