import { useState } from 'react';
import { useGameContext  } from '../../contexts/GameContext';
import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';

export const useTool = () => {

  const gameContext = useGameContext();
  const canvasContext = useDrawingContext();
  const brushContext = useBrushContext();

  const {
    gameInfoRef,
    userSetsRef,
    delayPremiumMenu,

    premiumFeaturesRef,
    setShowBrushMenu,
  } = gameContext;

  const {
    lastActionTimeRef,
  } = canvasContext;

  const {
  
    activeTool, setActiveTool,
    previousToolRef,

    color, setTheColorFun,
    eraserColorRef,
    brushColorRef,
    previousColorRef,

    lineWidth, setTheSizeFun,
    eraserSizeRef,
    brushSizeRef,
    previousLineWidthRef,

    showPalette,  setShowPalette,

  
  } = brushContext;

  function changeTool(newTool, fixed) {
    if (Date.now() - lastActionTimeRef.current < 300) {return;}

    if (newTool === 'brush' && activeTool === 'brush') {
      lastActionTimeRef.current = Date.now();
      return setShowBrushMenu(true);
    }
    
    lastActionTimeRef.current = Date.now();

    setShowPalette(false);
    // const game = gameInfoRef.current;
    // if ((game.shared || !game.word) && newTool === 'filler') { return }
  
    if (['filler', 'pipette'].includes(newTool)) {
      if (!premiumFeaturesRef.current.includes(newTool) || userSetsRef.current.premiumOff) {
        delayPremiumMenu('menu');
        return;
      }
    }

    
    if (newTool === activeTool && !fixed) {
      newTool = 'brush';
    }
  
    previousToolRef.current = activeTool;
    if (newTool !== activeTool) {
      setActiveTool(newTool);
    }
  }

  return {
    changeTool,
  };
};