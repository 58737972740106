import pencilTextureSrc from './textures/pencil150.png';
import pencilTextureBgSrc from './textures/pencil200bg.png';

let pencilTexture = null;
let pencilTextureBg = null;

// Функция для загрузки изображения и сохранения его в переменной
function loadTexture(src, callback) {
  const image = new Image();
  image.onload = () => callback(image);
  image.src = src;
}

// Загружаем текстуры при инициализации модуля
loadTexture(pencilTextureSrc, (img) => {
  pencilTexture = img;
});
loadTexture(pencilTextureBgSrc, (img) => {
  pencilTextureBg = img;
});


function seededRandom(seed) {
  const x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

export function drawPencilStroke({
  color,
  lineWidth,
  points,
  time,
}, context) {

  const outline = Math.min(6, Math.max(2, lineWidth / 3));

  applyPencilStroke({
    color,
    lineWidth: lineWidth + outline,
    points,
    time,
    texture: pencilTextureBg,
  }, context)

  applyPencilStroke({
    color,
    lineWidth,
    points,
    time,
    texture: pencilTexture,
  }, context)


}


function applyPencilStroke({
  color,
  lineWidth,
  points,
  time,
  texture,
}, context) {
  // if (points.length < 2 || !texture) return;

  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = context.canvas.width;
  bufferCanvas.height = context.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  bufferCtx.lineCap = 'round';
  bufferCtx.lineJoin = 'round';

  // Рисуем сплошной штрих
  bufferCtx.strokeStyle = 'black';
  bufferCtx.lineWidth = lineWidth;

  if (points.length === 1) {
    drawPoint(points, bufferCtx, lineWidth);
  } else if (points.length === 2) {
    drawStraightLine (points, bufferCtx)
  } else {
    drawStroke(points, bufferCtx);
  }

  // ------------

  bufferCtx.globalCompositeOperation = 'destination-in';
    
  // Создаем смещенный паттерн на основе time
  const offsetX = seededRandom(time) * texture.width;
  const offsetY = seededRandom(time + 1) * texture.height;
  
  const patternCanvas = document.createElement('canvas');
  patternCanvas.width = texture.width;
  patternCanvas.height = texture.height;
  const patternCtx = patternCanvas.getContext('2d');
  
  patternCtx.drawImage(texture, 0, 0);
  patternCtx.globalCompositeOperation = 'source-atop';
  patternCtx.drawImage(texture, offsetX, offsetY);
  
  const pattern = bufferCtx.createPattern(patternCanvas, 'repeat');
  bufferCtx.fillStyle = pattern;
  bufferCtx.fillRect(0, 0, bufferCanvas.width, bufferCanvas.height);

  // Применяем цвет
  bufferCtx.globalCompositeOperation = 'source-in';
  bufferCtx.fillStyle = color;
  bufferCtx.fillRect(0, 0, bufferCanvas.width, bufferCanvas.height);

  context.drawImage(bufferCanvas, 0, 0);
}


function drawStroke (points, bufferCtx) {

  
  bufferCtx.beginPath();
  bufferCtx.moveTo(points[0][0], points[0][1]);


  for (let i = 1; i < points.length; i++) {
    const nextPoint = points[i - 1];
    const currentPoint = points[i];
    const midPoint = [
      (nextPoint[0] + currentPoint[0]) / 2,
      (nextPoint[1] + currentPoint[1]) / 2
    ];

    bufferCtx.quadraticCurveTo(nextPoint[0], nextPoint[1], midPoint[0], midPoint[1]);
  }

  const lastPoint = points[points.length - 1]
  bufferCtx.lineTo(
    lastPoint[0], 
    lastPoint[1],
    );

  bufferCtx.stroke();

  
}

function drawStraightLine (points, bufferCtx) {

  let firstPoint = points[0];
  let secondPoint = points[1];

  // Рисуем линию между двумя точками
  bufferCtx.moveTo(
    firstPoint[0], 
    firstPoint[1],
  );
  if (Array.isArray(secondPoint)) {} else {secondPoint = [secondPoint.x, secondPoint.y]}

  bufferCtx.lineTo(
    secondPoint[0], 
    secondPoint[1],
  );
  bufferCtx.stroke();
  
}


function drawPoint (points, bufferCtx, lineWidth) {

  let point = points[0];
  if (Array.isArray(point)) {} else {point = [point.x, point.y]}
      
  // Для одиночной точки рисуем круг
  bufferCtx.beginPath();
  bufferCtx.arc(
    point[0], 
    point[1], 
    lineWidth / 2, 
    0, 
    Math.PI * 2
    );
  bufferCtx.fillStyle = 'black';
  bufferCtx.fill();

}