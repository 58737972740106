// useShapes.js

import { useCallback, useEffect, } from 'react';

import { useGameContext  } from '../../contexts/GameContext';
import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';
import { useHistoryContext  } from '../../contexts/HistoryContext';

export const useShapes = ({
  saveHistory,
  getCanvasPoint,
  getEventPos,
  redrawCanvas,

  changeViewStart,
  changeViewMove,

  touchZoomStart,
  touchZoomMove,

  wipeTempCanvas,

  addStroke,
}) => {

  const gameContext = useGameContext();
  const canvasContext = useDrawingContext();
  const brushContext = useBrushContext();
  const HistoryContext = useHistoryContext();

  const {
    activeUserIdRef
  } = gameContext;

  const {
    isDrawingRef,

    tempCanvasRef,
    touchMoveLastTimeRef,
    touchEndLastTimeRef,
  
  } = canvasContext;

  const {
    colorRef,
    lineWidthRef,

    longPressTimeoutRef,
    shapeStartPointRef,
    drawingShapeRef,
    lastShapeMadeTimeRef,
  } = brushContext;

  const {
    userStrokesRef,
  } = HistoryContext;


  const straightLine = ({ nativeEvent }) => {

    let myStrokes = userStrokesRef.current[activeUserIdRef.current];
    if (myStrokes.length === 0) return;

    let myHistory = myStrokes.filter(stroke=> !stroke.cancelled);
    const lastStroke = myHistory[myHistory.length - 1];

    if (!lastStroke?.points?.length) return;

    const {x, y} = getEventPos(nativeEvent);

    if (lastStroke.points.length === 1) {
      lastStroke.cancelled = true;
    } 

    if (nativeEvent.button === 2) {
      
      lastStroke.cancelled = true;
      addStroke([...lastStroke.points, {x, y}])

    } else {

      const lastPoint = lastStroke.points[lastStroke.points.length - 1];
      addStroke([lastPoint, {x, y}])

    }


  };



  const handleShapeStart = useCallback((event) => {

    shapeStartPointRef.current = getEventPos(event);  // Record the start point

    const activationTime = 
    lastShapeMadeTimeRef.current > Date.now() - 2000 ? 500 : 1000;

    clearTimeout(longPressTimeoutRef.current);
    longPressTimeoutRef.current = setTimeout(() => {

      const now = Date.now();

      if (
        now - touchMoveLastTimeRef.current > activationTime
        && now - touchEndLastTimeRef.current > activationTime
        ) {

        drawingShapeRef.current = true;
        isDrawingRef.current = false;

        wipeTempCanvas();
        window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')

      }

    }, activationTime);  

  }, [drawingShapeRef, drawingShapeRef, getEventPos, wipeTempCanvas]);


  const handleShapeMove = useCallback((event) => {

    if (!drawingShapeRef.current) return;

    const startPoint = shapeStartPointRef.current;
    const currentPoint = getEventPos(event);
    
    const tempCtx = tempCanvasRef.current.getContext('2d');
    wipeTempCanvas();
    tempCtx.beginPath();
    tempCtx.moveTo(startPoint.x, startPoint.y);
    tempCtx.lineTo(currentPoint.x, currentPoint.y);
    tempCtx.strokeStyle = colorRef.current;
    tempCtx.lineWidth = lineWidthRef.current;
    tempCtx.stroke();
  }, [getEventPos, wipeTempCanvas]);

  const handleShapeEnd = useCallback((event) => {
    clearTimeout(longPressTimeoutRef.current);

    addStroke([shapeStartPointRef.current, getEventPos(event.changedTouches[0])]);
    wipeTempCanvas(); // Clear preview canvas
    lastShapeMadeTimeRef.current = Date.now();
    
  }, [addStroke, saveHistory, wipeTempCanvas]);



  return {
    straightLine,

    handleShapeStart,
    handleShapeMove,
    handleShapeEnd,
  }
};

