// BrushSizeControl.js
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';

import BrushSizeIndicator from './BrushSizeIndicator'; 
// import { MdVerticalAlignCenter } from 'react-icons/md';

import CircleSwitch from "./CircleSwitch/CircleSwitch.tsx";
import HintPopup from "./HintPopup.js";


const BrushSizeControl = ({ 
  canvasContext,
  brushContext,

  path,
  icon,
  huawei,
 }) => {

    const { t } = useTranslation();

    const {
      visibleLineWidth, 
      setTheSizeFun, 
      color, 
      setShowPalette,
    
      isResizingBrush, setIsResizingBrush,
    
      multiplierTextColor,
      brushIndicatorFrame,
      softness,
    
    } = brushContext;

    const {
      zoomFactorRef,
      canvasSideRef,
    } = canvasContext;
  
    const [needHint, setNeedHint] = useState(true);
    
    const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 }); 
    const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 }); 

    const hasMoved = useRef(false);

    const [showHint, setShowHint] = useState(false);

    
    // Добавлено: Максимальный размер для отображения размера кисти
    const maxSize = 50; // Максимальный размер круга для отображения
    const displaySize = Math.min(visibleLineWidth, maxSize); // Размер для отображения
    const scale = visibleLineWidth / maxSize; // Коэффициент масштабирования

    const brushSizeText = brushContext.lineWidth.toFixed(Math.min(1, Math.floor(canvasContext.zoomFactor/8)));
    const bushOpacityText = Math.round(chroma(brushContext.color).alpha() * 100);

    const startResizing = (event) => {
        setIsResizingBrush(true);
        const x = event.clientX || event.touches[0]?.clientX;
        const y = event.clientY || event.touches[0]?.clientY;

        setClickPosition({ x, y }); // Обновление позиции кружка
        setPointerPosition({ x, y }); // Обновление позиции кружка

        hasMoved.startTime = Date.now();
        hasMoved.startTouchPoint = event?.touches?.[0] || event;
    };

    useEffect(() => {

        const handlePointerMove = (event) => {
            if (!isResizingBrush) return;
            const x = event.clientX || event.touches[0]?.clientX;
            const y = event.clientY || event.touches[0]?.clientY;

            const diffY = pointerPosition.y - y;
            setPointerPosition({ x, y }); // Обновление позиции кружка

            if (diffY === 0) {return;}

            const deviderCoef = 800 / canvasSideRef.current * 1.5;
            let lineWidthChange = Math.min(Math.max((diffY) / deviderCoef, -12), 12) / zoomFactorRef.current;

            // lineWidthChange = lineWidthChange.toFixed(2);
            // lineWidthChange = Math.round(lineWidthChange);

            setTheSizeFun(currentLineWidth => {

              const visualSize = currentLineWidth * zoomFactorRef.current;
              if (visualSize < 30) {
                lineWidthChange = lineWidthChange / Math.sqrt(30 - visualSize);
              } 
              return Math.max(1, currentLineWidth + lineWidthChange)

            })
            setShowHint(false);


            hasMoved.current = true; // Установка при перемещении
        };

        const handlePointerUp = (event) => {
            setIsResizingBrush(false);

            if (hasMoved.startTouchPoint) {
              const y = event.clientY || event.touches[0]?.clientY;
              const diffY = hasMoved.startTouchPoint.clientY - y;

              if (Date.now() - hasMoved.startTime < 200 && Math.abs(diffY) < 5) {
                setShowHint(true);
                setNeedHint(false);
              } else {
                setNeedHint(false);
              }
            }

      
        };

        if (isResizingBrush) {
            document.addEventListener('mousemove', handlePointerMove);
            document.addEventListener('mouseup', handlePointerUp);
            document.addEventListener('touchmove', handlePointerMove);
            document.addEventListener('touchend', handlePointerUp);
        }

        return () => {
            document.removeEventListener('mousemove', handlePointerMove);
            document.removeEventListener('mouseup', handlePointerUp);
            document.removeEventListener('touchmove', handlePointerMove);
            document.removeEventListener('touchend', handlePointerUp);
        };

    }, [isResizingBrush, visibleLineWidth, setTheSizeFun, pointerPosition.y]);


    const blurSize = `${softness * displaySize}px`; // Пример коэффициента умножения на 3

    return (
        <div
            onMouseDown={(e)=>{
              startResizing(e);
              setShowPalette(false);
            }}
            onTouchStart={startResizing}
            style={{
                width: '50px',
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'ns-resize',
                userSelect: 'none',
                position: 'relative',
                zIndex: 200,
            }}
        >
            <div style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 201,  // Make sure this layer is above all others
                opacity: 0,  // Invisible but still interactive
                top: 0,
                left: 0
            }}></div>

            <div style={{
                width: `${displaySize}px`,
                height: `${displaySize}px`,
                borderRadius: '50%',
                background: color,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                zIndex: 99,
                boxShadow: softness ? 0 : brushIndicatorFrame,
                filter: `blur(${blurSize})`, 

            }}>
              <span style={{
                  color: multiplierTextColor,
                  fontSize: '13px',
                  position: 'absolute',
                  left: '51%',
                  top: '47%',
                  transform: 'translate(-50%, -50%)',
                  opacity: scale > 1 ? 0.8 : 0
              }}>
                  x{scale.toFixed(2)}
              </span>
            </div>
              <div style={{
                opacity: (visibleLineWidth < 35) ? 1 : 0,
                transition: 'opacity 200ms',
              }}>
                <CircleSwitch
                  path= {needHint ? path : null}
                  icon= {needHint ? icon : null}
                  huawei={huawei}
                  isActive={isResizingBrush}
                  cursor='ns-resize'
                />     
                </div>

              <div className="brush-info">
                {brushSizeText}px<br/>
                {bushOpacityText}%
              </div>


            {isResizingBrush && (
               <BrushSizeIndicator
                canvasContext={canvasContext}
                brushContext={brushContext}
                color={color}
                position={pointerPosition}
                clickPosition={clickPosition}
                visibleLineWidth={visibleLineWidth}
                brushIndicatorFrame={brushIndicatorFrame}
                softness={softness}
              />
            )}
            
            { 
              showHint && 
              (<HintPopup
                message={t('tooltip.line_width_button')}
                isVisible={showHint}
                onClose={() => setShowHint(false)}
                duration={2500}
                top="385px"
                left="50%"
                width="300px"
              />)
          }
            

        </div>
    );
};

export default BrushSizeControl;