import React from 'react';
import chroma from 'chroma-js';

function RecentColorsPalette({ 
  brushContext,
  onClose
 }) {

  const {
    lastColorsRef, 
    setTheColorFun, 
    premiumGrayscale,
  } = brushContext;

  function getBoxShadow(actualColor) {
    const chromaColor = chroma(actualColor);
    const lightness = chromaColor.luminance();

    return (lightness > 0.9 || chromaColor.alpha() < 0.1) ? '0 0 0 1px rgba(30, 30, 30, 0.3)' : '';
  }

  const convertColorToGrayscale = (savedColor=>{
    const alpha = chroma(savedColor).alpha(); // Сохраняем прозрачность текущего цвета
    let rgb = chroma(savedColor).rgb();
    let average = (rgb[0] + rgb[1] + rgb[2]) / 3;
    savedColor = chroma([average, average, average]).alpha(alpha).hex();
    return savedColor;
  })


  const handleColorPick = (pickedColor=>{
    if (premiumGrayscale) {pickedColor = convertColorToGrayscale(pickedColor)}
    setTheColorFun(pickedColor);
  })

  const lastColors = lastColorsRef.current.slice(-8);
  const firstColumnColors = lastColors.length > 4 ? lastColors.slice(-4) : lastColors;
  const secondColumnColors = lastColors.length > 4 ? lastColors.slice(0, lastColors.length - 4) : [];

  firstColumnColors.unshift('#000000FF');
  secondColumnColors.unshift('#FFFFFFFF');

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'row', 
      justifyContent: 'flex-end',
      alignItems: 'flex-start',  // Здесь изменено для вертикального выравнивания вниз
      gap: '10px',
      pointerEvents: 'none', 
    }}>
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'flex-start', 
        alignItems: 'center', 
        gap: '10px',
        pointerEvents: 'none', 
        }}>

        {firstColumnColors.map((color, index) => (
          <div key={index} 
            // onClick={() => { handleColorPick(color); onClose(); }}
            onMouseDown={() => { handleColorPick(color); onClose(0); }}
            onTouchStart={() => { handleColorPick(color); onClose(500); }}
            style={{ 
              width: '30px', 
              height: '30px', 
              borderRadius: '50%', 
              backgroundColor: color, 
              cursor: 'pointer', 
              boxShadow: getBoxShadow(color),
              pointerEvents: 'auto',
            }} />
        ))}
      </div>
      {secondColumnColors.length > 0 && (
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'flex-start', 
          alignItems: 'center', 
          gap: '10px', 
          pointerEvents: 'none', 
          }}>
          {secondColumnColors.map((color, index) => (
            <div key={index} 
              // onClick={() => { handleColorPick(color); onClose(); }}
              onMouseDown={() => { handleColorPick(color); onClose(0); }}
              onTouchStart={() => { handleColorPick(color); onClose(500); }}

              style={{ 
                width: '30px', 
                height: '30px', 
                borderRadius: '50%', 
                backgroundColor: color, 
                cursor: 'pointer', 
                boxShadow: getBoxShadow(color),
                zIndex: 1200,
                pointerEvents: 'auto',
              }} />
          ))}
        </div>
      )}
    </div>
  );
}

export default RecentColorsPalette;
