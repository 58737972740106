export function showZoomed({
  stroke,
  canvas, 
  imageCache,
}) {

  const panOffset = {x: 0, y: 0};
  const zoomFactor = 1;

  const imageData = imageCache.current.get(stroke.time);
  if (imageData.ignore) { return; }

  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = canvas.width;
  tempCanvas.height = canvas.height;
  const tempCtx = tempCanvas.getContext('2d');
  tempCtx.putImageData(imageData, 0, 0);

  const area = {
    x: panOffset.x / zoomFactor,
    y: panOffset.y / zoomFactor,
    width: canvas.width / zoomFactor + 1,
    height: canvas.height / zoomFactor + 1,
  }

  const areaCanvas = document.createElement('canvas');
  areaCanvas.width = area.width;
  areaCanvas.height = area.height;
  const areaCtx = areaCanvas.getContext('2d');

  areaCtx.translate(area.x, area.y);
  // Вырезаем нужную область из кэшированного изображения
  areaCtx.drawImage(tempCanvas, 0, 0);

  const resizeCanvas = document.createElement('canvas');
  resizeCanvas.width = canvas.width;
  resizeCanvas.height = canvas.height;
  const resizeCtx = resizeCanvas.getContext('2d');

  resizeCtx.scale(zoomFactor, zoomFactor);
  resizeCtx.drawImage(areaCanvas, 0, 0);

  // Накладываем полученное изображение на основной канвас
  const canvasCtx = canvas.getContext('2d');
  // Забелить, чтобы избавиться от прозрачности
  canvasCtx.fillStyle = 'rgba(255, 255, 255, 1)'; 
  canvasCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

  canvasCtx.drawImage(resizeCanvas, 0, 0);
}


export function freeMemory({
  imageCache,
  lastStrokeTime,
}) {
  // Подготовка массива ключей для удаления
  const keysToRemove = Array.from(imageCache.current.keys())
    .filter(time => time > lastStrokeTime);

  keysToRemove.forEach(key => {
    imageCache.current.delete(key);
  });


  // Оставить только последние 20 кэшей
  const allKeys = Array.from(imageCache.current.keys());
  const sortedKeys = allKeys.sort((a, b) => b - a); // Сортировка по убыванию времени

  if (sortedKeys.length > 20) {
    const keysToKeep = sortedKeys.slice(0, 20); // 20 самых новых ключей
    allKeys.forEach(key => {
      if (!keysToKeep.includes(key)) {
        imageCache.current.delete(key);
      }
    });
  }
}