// OpacityControl.js
import React, { useState, useRef, useEffect } from 'react';
import CircleSwitch from "./CircleSwitch/CircleSwitch.tsx";
import ColorIndicator from './ColorIndicator'; 

const SoftnessControl = ({ 
  canvasContext,
  gameContext,
  brushContext,
  drawMethods,

  path,
  icon,
  huawei,
 }) => {

    const {
      userSetsRef,
      delayPremiumMenu,
      premiumFeaturesRef,
    } = gameContext;

    const {
      color, 
      activeTool, 
      visibleLineWidth,
      brushIndicatorFrame,

      isChangingSoftness, setIsChangingSoftness,
      softness,
      setSoftness,
    } = brushContext;

    const {
      changeTool, 
    } = drawMethods;

    const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 }); 
    const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 }); 
    const hasMoved = useRef(false);

    const startChangingOpacity = (event) => {
      setIsChangingSoftness(true);

        const x = event.clientX || event.touches[0]?.clientX;
        const y = event.clientY || event.touches[0]?.clientY;

        setClickPosition({ x, y }); // Обновление позиции кружка
        setPointerPosition({ x, y }); // Обновление позиции кружка

        hasMoved.startTime = Date.now();
        hasMoved.startTouchPoint = event?.touches?.[0] || event;
    };

    useEffect(() => {
        const handlePointerMove = (event) => {
            if (!isChangingSoftness) return;
            const x = event.clientX || event.touches[0]?.clientX;
            const y = event.clientY || event.touches[0]?.clientY;

            let diffY = pointerPosition.y - y;
            setPointerPosition({ x, y }); // Обновление позиции кружка
            
            if (diffY === 0) return;
            if (diffY < 0) {diffY *= 1.5;}
            const softnessChange = diffY * 0.002;

            setSoftness(softness => {
              // return Math.max(0, Math.min(0, softness + softnessChange));
              return Math.max(0, Math.min(0.3, softness + softnessChange));
            })
            hasMoved.current = true; // Установка при перемещении

        };

        const handlePointerUp = () => {
          setIsChangingSoftness(false);
            if (hasMoved.startTouchPoint && Date.now() - hasMoved.startTime < 350) {
              changeTool("brush")
            } 
            setSoftness(softness => {

              let newSoftness = softness < 0.02 ? 0 : softness;
              if (
                newSoftness > 0 && 
                (!premiumFeaturesRef.current.includes('softBrush') 
                || userSetsRef.current.premiumOff)
                ) {
                delayPremiumMenu('menu');
                newSoftness = 0;
              }
              return newSoftness;

            })
        };

        if (isChangingSoftness) {
            document.addEventListener('mousemove', handlePointerMove);
            document.addEventListener('mouseup', handlePointerUp);
            document.addEventListener('touchmove', handlePointerMove);
            document.addEventListener('touchend', handlePointerUp);
        }

        return () => {
            document.removeEventListener('mousemove', handlePointerMove);
            document.removeEventListener('mouseup', handlePointerUp);
            document.removeEventListener('touchmove', handlePointerMove);
            document.removeEventListener('touchend', handlePointerUp);
        };
    }, [isChangingSoftness, setIsChangingSoftness, pointerPosition]);

    return (
      <div>
        <div
            onMouseDown={startChangingOpacity}
            onTouchStart={startChangingOpacity}
            style={{
                cursor: 'pointer', 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '50px',
                height: '50px',
            }}
        >
            <CircleSwitch
									path={path}
									icon={icon}
                  huawei={huawei}
									isActive={activeTool === "brush"}
								/>
        </div>
        {isChangingSoftness && (
                <ColorIndicator
                  canvasContext={canvasContext}
                  color={color}
                  position={pointerPosition}
                  clickPosition={clickPosition}
                  visibleLineWidth={visibleLineWidth}
                  brushIndicatorFrame={brushIndicatorFrame}
                  softness={softness < 0.02 ? 0 : softness}
                  />
            )}
      </div>
    );
};

export default SoftnessControl;