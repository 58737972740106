import { useRef, useEffect, useState, useCallback, } from 'react';

import { useGameContext } from '../../contexts/GameContext';
import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';
import { useHistoryContext  } from '../../contexts/HistoryContext';

import { drawTaperingStroke } from './brushes/tapering';
import { drawPencilStroke } from './brushes/pencil';


export const useSoftDraw = ({

  wipeTempCanvas,

  saveHistory,
  getCanvasPoint,
  getEventPos,
  redrawCanvas,

  changeViewStart,
  changeViewMove,

  touchZoomStart,
  touchZoomMove,
  
}) => {

  const gameContext = useGameContext();
  const canvasContext = useDrawingContext();
  const brushContext = useBrushContext();
  const HistoryContext = useHistoryContext();

  const {
    activeUserIdRef,
    gameInfoRef,
  } = gameContext;

  const {
    tempCanvasRef,
    tempContextRef,
    canvasRef,
    contextRef,

    lastActionTimeRef,
    isDrawingRef,

  } = canvasContext;

  const {
    color,
    colorRef,

    lineWidth,
    lineWidthRef,
    showPalette, setShowPalette,

    softness,
    softnessRef,

    brushTypeRef,
    activeToolRef,

  } = brushContext;

  const {
    userStrokesRef,
    setRedrawer,
  } = HistoryContext;

  const lastPointRef = useRef([]);
  const currentStrokeRef = useRef([]);

  function applyBlurAtTouch({nativeEvent}) {
    
    const {x, y} = getEventPos(nativeEvent);
    const radius = lineWidthRef.current; // Радиус размытия
    const intensity = 10; // Максимальная интенсивность размытия
    const levels = 5; // Количество уровней размытия

    const context = contextRef.current;
    context.save();

    // Сохраняем исходное изображение для последующего восстановления
    const originalData = context.getImageData(x - radius - intensity, y - radius - intensity, (radius + intensity) * 2, (radius + intensity) * 2);

    for (let i = 0; i < levels; i++) {
        const currentRadius = radius - (i * radius / levels);
        const currentIntensity = intensity * ((i + 1) / levels);

        // Временный канвас для применения эффекта блюра
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        tempCanvas.width = (radius + intensity) * 2;
        tempCanvas.height = (radius + intensity) * 2;

        // Вставляем исходное изображение
        tempCtx.putImageData(originalData, 0, 0);
        tempCtx.filter = `blur(${currentIntensity}px)`;

        // Рисуем размытую версию изображения
        tempCtx.drawImage(tempCanvas, 0, 0);

        // Вырезаем круглую область
        tempCtx.globalCompositeOperation = 'destination-in';
        tempCtx.beginPath();
        tempCtx.arc(radius + intensity, radius + intensity, currentRadius, 0, Math.PI * 2);
        tempCtx.fillStyle = '#FFF';
        tempCtx.fill();

        // Возвращаем размытую область на основной канвас
        context.drawImage(tempCanvas, x - radius - intensity, y - radius - intensity);
    }

    context.restore();
}

  const addStroke = (currentStroke) => {

    const points = [...currentStroke].map(point=>{
      if (Array.isArray(point)) {return point}
      else {return [point.x, point.y]}
    });

    const newStroke = {
        color: colorRef.current, 
        lineWidth: lineWidthRef.current, 
        softness: softnessRef.current, 
        points,
        time: Date.now(), // Добавляем метку времени к штриху
        type: 'stroke', // Указываем тип записи как штрих
        userId: activeUserIdRef.current,
        brush: activeToolRef.current === 'eraser' ? 'plain' : brushTypeRef.current,
    };

    let myStrokes = userStrokesRef.current[activeUserIdRef.current];

    myStrokes.push(newStroke);
    myStrokes = myStrokes.filter(stroke=> !stroke.cancelled);

    userStrokesRef.current[activeUserIdRef.current] = myStrokes;
    setRedrawer(i => i + 1);
    saveHistory();

  };

  const startDrawing = ({ nativeEvent }) => {

    if (showPalette) { setShowPalette(false); return;}
    if (Date.now() - lastActionTimeRef.current < 100) {return;}

    isDrawingRef.current = true;
    if (gameInfoRef.current?.mode === 'line') { redrawCanvas(); }

    const point = getEventPos(nativeEvent);

    tempContextRef.current.save();
    tempContextRef.current.beginPath();
    tempContextRef.current.strokeStyle = colorRef.current; 
    tempContextRef.current.lineWidth = lineWidthRef.current;

    const actualBrushType = activeToolRef.current === 'eraser' ? 'plain' : brushTypeRef.current;
    if (actualBrushType === 'pencil') {tempContextRef.current.globalAlpha = 0.7;}

    if (softnessRef.current > 0) {
      const blurSize = tempContextRef.current.lineWidth * softnessRef.current;
      tempContextRef.current.filter = `blur(${blurSize}px)`;
    } 
    tempContextRef.current.moveTo(point.x, point.y);

    lastPointRef.current = point;
    currentStrokeRef.current = [point]

  };

  const draw = ({ nativeEvent }) => {
    if (!isDrawingRef.current) return;

    const point = getEventPos(nativeEvent)
    const lastPoint = lastPointRef.current;

    const midPoint = { x: (lastPoint.x + point.x) / 2, y: (lastPoint.y + point.y) / 2 };

    wipeTempCanvas();

    const actualBrushType = activeToolRef.current === 'eraser' ? 'plain' : brushTypeRef.current;

    if (actualBrushType === 'feather') {

      lastPointRef.current = point;
      currentStrokeRef.current.push(point);
      drawTaperingStroke({
        color: colorRef.current,
        lineWidth: lineWidthRef.current,
        points: currentStrokeRef.current.map(point=>[point.x, point.y]),
      },tempContextRef.current);

    } else {
      tempContextRef.current.quadraticCurveTo(lastPointRef.current.x, lastPointRef.current.y, midPoint.x, midPoint.y);
      // tempContextRef.current.lineTo(point.x, point.y);
      tempContextRef.current.stroke();
      lastPointRef.current = point;
      currentStrokeRef.current.push(point);
    }


  };

  const finishDrawing = ({ nativeEvent }) => {
    if (!isDrawingRef.current) return;

    tempContextRef.current.closePath();
    isDrawingRef.current = false;
    addStroke(currentStrokeRef.current);

    currentStrokeRef.current = [];

    wipeTempCanvas();
    tempContextRef.current.restore();

  };
   

  const startDrawingTouch = (event) => {

    if (Date.now() - lastActionTimeRef.current < 300) {return;}

    event.preventDefault(); 

    if (showPalette) { setShowPalette(false); return;}
    if (event.touches.length > 1) {
      if (currentStrokeRef.current.length < 5) {
        currentStrokeRef.current = [];
        wipeTempCanvas();
      } else {
        finishDrawingTouch();
      }
      return touchZoomStart(event);
    }

    const touch = event.touches[0]; // Берем первое касание
    const touchPos = getEventPos(touch);

    isDrawingRef.current = true;
    if (gameInfoRef.current?.mode === 'line') { redrawCanvas(); }

    tempContextRef.current.save();
    tempContextRef.current.beginPath(); 
    tempContextRef.current.strokeStyle = colorRef.current;
    tempContextRef.current.lineWidth = lineWidthRef.current;


    const actualBrushType = activeToolRef.current === 'eraser' ? 'plain' : brushTypeRef.current;

    if (actualBrushType === 'pencil') {tempContextRef.current.globalAlpha = 0.7;}

    if (softnessRef.current > 0) {
      const blurSize = tempContextRef.current.lineWidth * softnessRef.current;
      tempContextRef.current.filter = `blur(${blurSize}px)`;
    } 

    tempContextRef.current.moveTo(touchPos.x, touchPos.y); // Необходимо для инициализации пути в точке касания

    lastPointRef.current = touchPos;
    currentStrokeRef.current = [touchPos]
  };

  const drawTouch = (event) => {

    event.preventDefault();
    if (event.touches.length === 2) {
      return touchZoomMove(event);
    }

    if (!isDrawingRef.current) return;
    const touch = event.touches[0];
    const touchPos = getEventPos(touch);
    const lastPoint = lastPointRef.current;

    const midPoint = { x: (lastPoint.x + touchPos.x) / 2, y: (lastPoint.y + touchPos.y) / 2 };

    wipeTempCanvas();

    const actualBrushType = activeToolRef.current === 'eraser' ? 'plain' : brushTypeRef.current;


    if (actualBrushType === 'feather') {

      lastPointRef.current = touchPos;
      currentStrokeRef.current.push(touchPos);
      drawTaperingStroke({
        color: colorRef.current,
        lineWidth: lineWidthRef.current,
        points: currentStrokeRef.current.map(point=>[point.x, point.y]),
      }, tempContextRef.current);

    } else  {

      tempContextRef.current.lineTo(touchPos.x, touchPos.y);
      // tempContextRef.current.quadraticCurveTo(lastPointRef.current.x, lastPointRef.current.y, midPoint.x, midPoint.y);
      tempContextRef.current.stroke();
      lastPointRef.current = touchPos;
      currentStrokeRef.current.push(touchPos);

    }


  };

  const finishDrawingTouch = () => {
    if (!isDrawingRef.current) return;
    isDrawingRef.current = false;

    // Завершаем штрих, если currentStroke содержит более одной точки
    if (currentStrokeRef.current.length > 1) {
      addStroke(currentStrokeRef.current);
    } else if (currentStrokeRef.current.length === 1){
      // Если было одиночное касание, рисуем точку
      drawPoint(currentStrokeRef.current[0]);
    }
    wipeTempCanvas();
    tempContextRef.current.restore();

  };
  
  const drawPoint = (point) => {
    const { x, y } = point;
    tempContextRef.current.beginPath();
    tempContextRef.current.arc(x, y, lineWidthRef.current / 2, 0, 2 * Math.PI);
    tempContextRef.current.fill();
  };


  const fill = ({nativeEvent}) => {

    if (showPalette) { 
      lastActionTimeRef.current = Date.now();
      setShowPalette(false); return;
    }
    if (Date.now() - lastActionTimeRef.current < 100) {return;}
    lastActionTimeRef.current = Date.now();

    const { x, y } = getEventPos(nativeEvent);

    let fillStroke;
    if (ifBackgroundFill()) {
      
      fillStroke = {
        time: Date.now(),
        userId: activeUserIdRef.current,
        type: 'background',
        color: colorRef.current,
      }

    } else {
      fillStroke = {
        time: Date.now(),
        userId: activeUserIdRef.current,
        type: 'fill',
        x: x,
        y: y,
        color: colorRef.current,
      };
    }

    let myStrokes = userStrokesRef.current[activeUserIdRef.current];

    myStrokes.push(fillStroke);
    myStrokes = myStrokes.filter(stroke=> !stroke.cancelled);

    userStrokesRef.current[activeUserIdRef.current] = myStrokes;
    setRedrawer(i => i + 1);
    saveHistory ()

  }

  function ifBackgroundFill () {

    const allStrokes = Object.values(userStrokesRef.current).flat();
    const combinedStrokes = allStrokes.filter(stroke => !stroke.cancelled).sort((a, b) => a.time - b.time);
    const lastClearIndex = combinedStrokes.map(stroke => stroke.type).lastIndexOf('clear');

    const strokesToRender = combinedStrokes.slice(lastClearIndex + 1);
    const hasUsualStrokes = strokesToRender.find(stroke=>stroke.type !== 'background');
    return !hasUsualStrokes;
    
  }

  return {

    applyBlurAtTouch,
    addStroke,

    startDrawing,
    draw,
    finishDrawing,

    startDrawingTouch,
    drawTouch,
    finishDrawingTouch,

    fill,
  }
};

