// useGameLoader.js
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const useGameLoader = ({

  socketRef, 
  socketStatus, 
  gameId, 
  groupId, 
  telegramUserId,
  activeUserIdRef,
  moderatorModeRef,
  moderatorIdRef,
  isValid,
  
  setGameInfo,
  setGameIsLoaded,
  gameInfoRef, 
  setCanDraw,

  setGameError,

  userSetsRef,
  groupSetsRef,
  botSetsRef,

  imageCache,
  updatePngCache,


}) => {

    const { i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const retryCountRef = useRef(0);  // Добавлено для контроля количества попыток

    useEffect(() => {

        if (
          isValid
          && !gameInfoRef.current 
          && socketStatus === 'connected' 
          && !isLoading) {

            setIsLoading(true);

            loadGameData({
              socket: socketRef.current,
              gameId,
              groupId,
              telegramUserId,
              gameInfoRef,
              setGameInfo,
              setIsLoading,

              retryCountRef,
          });
            
        }

    }, [socketStatus, isValid]);

    // Функция загрузки данных игры
    const loadGameData = ({
        socket, 
        gameId,
        groupId,
        telegramUserId, 

        gameInfoRef,
        setGameInfo,

        retryCountRef,
    }) => {

        if (socket && !isLoading) {

            socket.emit('loadGame', { 
              gameId, 
              groupId, 
              userId: telegramUserId, 
            }, async (loaded = {}) => {

                let {game, user, group, bot} = loaded;

                setIsLoading(false);
                if (!game) {return;}
                if (game.error) { return setGameError(game) } 

                // Настройки пользователя
                Object.assign(userSetsRef.current, user || {})
                Object.assign(groupSetsRef.current, group || {})
                Object.assign(botSetsRef.current, bot || {})

                game = Object.assign({
                  chat: [],
                  // chat: [
                  //   {author: 'Козлик', text: 'Привет'},
                  //   {author: 'Мартышка', text: 'хай'},
                  //   {author: 'Ути', text: 'Пыр фыр пыр мыр гыр пыр выр мыр'},
                  // ],
                }, game);

                if (game.png) { await updatePngCache(game, imageCache) }
                gameInfoRef.current = game;
                if (userSetsRef.current.moderEditing === gameId) {
                  moderatorModeRef.current = true;
                }

                setGameInfo(game);

                let ifCanDraw;

                if (game?.notFound) {
                  ifCanDraw = false;
                } else if (game.mode === 'sprint') {
                  if (game.word) {
                    ifCanDraw = true;
                  } else {
                    ifCanDraw = false;
                  }
                } else if (!game.word) {
                  ifCanDraw = true;
                } else if (telegramUserId) {
                  if (`${game?.user_key}` === `${telegramUserId}`) {
                    ifCanDraw = true;
                  } else if (game.shared?.includes(`${telegramUserId}`)) {
                    ifCanDraw = true;
                  } else if (userSetsRef.current.moder && moderatorModeRef.current) {
                    moderatorIdRef.current = telegramUserId;
                    activeUserIdRef.current = game.user_key;
                    ifCanDraw = true;
                  }
                }

                setCanDraw(ifCanDraw);
                i18n.changeLanguage(game?.locale || 'ru');

                setGameIsLoaded(true);

            });
        }

    };

    return isLoading;  // Можно вернуть isLoading, если хотите отображать индикатор загрузки где-то в UI
};

export default useGameLoader;