export function drawTaperingStroke({
  color,
  lineWidth,
  points,
  softness,
}, context) {
  if (points.length === 0) return;

  // Создаем отдельный буфер
  const bufferCanvas = document.createElement('canvas');
  bufferCanvas.width = context.canvas.width;
  bufferCanvas.height = context.canvas.height;
  const bufferCtx = bufferCanvas.getContext('2d');

  bufferCtx.lineCap = 'round';
  bufferCtx.lineJoin = 'round';

  let numInterpolations = Math.max(5, lineWidth/5);
  numInterpolations = Math.min(numInterpolations, 20);
  numInterpolations = Math.ceil(numInterpolations);

  const interpolatedPoints = interpolatePoints(points, numInterpolations);
  const totalPoints = interpolatedPoints.length;
  const centerPartStart = Math.min(numInterpolations * numInterpolations, Math.floor(totalPoints / 2.2));
  const centerPartEnd = Math.max(totalPoints - numInterpolations * numInterpolations, Math.ceil(totalPoints / 2.2));

  // Рисуем на буфере непрозрачной линией
  bufferCtx.strokeStyle = 'rgba(255, 255, 255, 1)';

  for (let i = 0; i < totalPoints - 1; i++) {
    const startPoint = interpolatedPoints[i];
    const endPoint = interpolatedPoints[i + 1];
    const segmentWidth = computeLineWidth(i, totalPoints, lineWidth, centerPartStart, centerPartEnd);

    bufferCtx.beginPath();
    bufferCtx.moveTo(startPoint[0], startPoint[1]);
    bufferCtx.lineTo(endPoint[0], endPoint[1]);
    bufferCtx.lineWidth = segmentWidth;
    bufferCtx.stroke();
  }

  // Применяем цвет и прозрачность к буферу
  bufferCtx.globalCompositeOperation = 'source-in';
  bufferCtx.fillStyle = color;
  bufferCtx.fillRect(0, 0, bufferCanvas.width, bufferCanvas.height);

  // Рисуем буфер на основном холсте
  context.drawImage(bufferCanvas, 0, 0);
}



function interpolatePoints(points, numInterpolations) {
  function catmullRomSpline(p0, p1, p2, p3, t) {
    const t2 = t * t;
    const t3 = t2 * t;
    return [
      0.5 * ((2 * p1[0]) + (-p0[0] + p2[0]) * t +
        (2 * p0[0] - 5 * p1[0] + 4 * p2[0] - p3[0]) * t2 +
        (-p0[0] + 3 * p1[0] - 3 * p2[0] + p3[0]) * t3),
      0.5 * ((2 * p1[1]) + (-p0[1] + p2[1]) * t +
        (2 * p0[1] - 5 * p1[1] + 4 * p2[1] - p3[1]) * t2 +
        (-p0[1] + 3 * p1[1] - 3 * p2[1] + p3[1]) * t3)
    ];
  }

  let interpolatedPoints = [];
  for (let i = 0; i < points.length - 1; i++) {
    const p0 = i > 0 ? points[i - 1] : points[i];
    const p1 = points[i];
    const p2 = points[i + 1];
    const p3 = i < points.length - 2 ? points[i + 2] : p2;

    interpolatedPoints.push(p1);
    for (let j = 1; j <= numInterpolations; j++) {
      const t = j / (numInterpolations + 1);
      interpolatedPoints.push(catmullRomSpline(p0, p1, p2, p3, t));
    }
  }
  interpolatedPoints.push(points[points.length - 1]);
  return interpolatedPoints;
}


function computeLineWidth(index, totalPoints, lineWidth, centerPartStart, centerPartEnd, smoothingFactor = 0.5) {
  function easeInOutQuad(t) {
    return t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;
  }

  function interpolate(start, end, t) {
    const smoothT = easeInOutQuad(t);
    return start + (end - start) * (smoothingFactor * smoothT + (1 - smoothingFactor) * t);
  }

  const minWidth = 0.5;
  
  if (index < centerPartStart) {
    const t = index / centerPartStart;
    return interpolate(minWidth, lineWidth, t);
  } else if (index >= centerPartEnd) {
    const t = (totalPoints - index) / (totalPoints - centerPartEnd);
    return interpolate(minWidth, lineWidth, t);
  } else {
    return lineWidth;
  }
}


function computeLineWidth0(index, totalPoints, lineWidth, centerPartStart, centerPartEnd) {
  if (index < centerPartStart) {
    return 0.5 + (lineWidth - 0.5) * (index / centerPartStart);
  } else if (index >= centerPartEnd) {
    return 0.5 + (lineWidth - 0.5) * ((totalPoints - index) / (totalPoints - centerPartEnd));
  } else {
    return lineWidth;
  }
}


