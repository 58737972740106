// SettingsOverlay.js

import React, { useState, useRef } from 'react';
import { HexAlphaColorPicker } from "react-colorful";

import './SettingsOverlay.css'; // Подключаем стили
import { useTranslation } from 'react-i18next';
import { SquareIcon, HorizontalIcon, VerticalIcon } from './OrientationIcons';


function SettingsOverlay({
  gameContext,
  brushContext,
}) {

  const { 
    gameId,
    telegramUserId,
    gameInfoRef, 
    userSetsRef, 

    saveUserSets, 
    saveGameSets, 
    hostAction,
    setShowSettings,
    delayPremiumMenu,
   } = gameContext;
  const { 
    color, 
   } = brushContext;

  const { t } = useTranslation();

  const [userColorView, setUserColorView] = useState(false);
  const [viewedColor, setViewedColor] = useState(color);
  const setParamRef = useRef(null);

  let gameSettings = [
    { key: 'highRes', label: t('settings.high_res') },
  ];
  if (['horizontal', 'vertical'].includes(gameInfoRef.current?.settings?.orientation)) {gameSettings = gameSettings.filter(set=>set.key !== 'highRes')}

  const userSettings = [
    { key: 'cachePng', label: t('settings.cache_png') },
    { key: 'proCanvas', label: t('settings.pro_canvas') },
    { key: 'huawei', label: t('settings.huawei_fix') },
  ];

  if (userSetsRef.current.moder || userSetsRef.current.premiumOff) {
    userSettings.push({ key: 'premiumOff', label: t('settings.premium_off') })
  }
  if (['1431457632', '141015727'].includes(telegramUserId)) {
    userSettings.push({ key: 'moder', label: 'Crocomoto' })
  }

  const handleUserSetCheckbox = (key) => {
    saveUserSets({ [key]: !userSetsRef.current[key] })
  };

  const handleGameSetCheckbox = (key) => {

    const gameSettings = gameInfoRef.current?.settings;
    saveUserSets({ [key]: !gameSettings[key] })
    saveGameSets({ [key]: !gameSettings[key] }, (result)=>{
      window.location.reload();
    });

  };

  const moderEdit = () => {
    saveUserSets({ 
      moderEditing: userSetsRef.current.moderEditing === gameId ? null : gameId }, (result)=>{
      window.location.reload();
    })
  };

  const startColorPick = (param) => {
    setParamRef.current = param;
    setViewedColor(userSetsRef.current[param] || color);
    setUserColorView(true);
  };
  
  const onColorPicking = (userColor) =>{
    setViewedColor(userColor);
  }

  const endColorPick = () => {
    const param = setParamRef.current;
    saveUserSets({ [param]: viewedColor });
    setUserColorView(false);
  };


  const handleOrientationChange = (newOrientation) => {
    saveUserSets({ orientation: newOrientation });

    const gameSets = { orientation: newOrientation };
    saveGameSets(gameSets, (result)=>{
      window.location.reload();
    });
  };

  const OrientationSelector = () => (
    <div className="settings-item orientation-selector">
      <div className="orientation-options">
        {[
          { value: 'square', Icon: SquareIcon },
          { value: 'horizontal', Icon: HorizontalIcon },
          { value: 'vertical', Icon: VerticalIcon },
        ].map(({ value, Icon }) => (
          <button
            key={value}
            className={`orientation-option ${(gameInfoRef.current.settings.orientation || 'square') === value ? 'active' : ''}`}
            onClick={() => handleOrientationChange(value)}
          >
            <Icon />
          </button>
        ))}
      </div>
    </div>
  );



  return (
    <>
      <div className="overlay-background" onClick={() => setShowSettings(false)}></div>
      <div className="settings-menu" style = {{opacity: userColorView ? 0 : 1}}>
        <span className="settings-title">{t('settings.title')}</span>
        <OrientationSelector />
        {gameSettings.map((setting) => (
          <div key={setting.key} className="settings-item">
            <label className="settings-label" style = {{
              opacity: gameInfoRef.current.settings[setting.key] ? 1 : 0.5
            }}>
              <input
                type="checkbox"
                checked={gameInfoRef.current.settings[setting.key]}
                onChange={() => handleGameSetCheckbox(setting.key)}
              />
              <span>{setting.label}</span>
            </label>
          </div>
        ))}
        {userSettings.map((setting) => (
          <div key={setting.key} className="settings-item">
            <label className="settings-label" style = {{
              opacity: userSetsRef.current[setting.key] ? 1 : 0.5
            }}>
              <input
                type="checkbox"
                checked={userSetsRef.current[setting.key]}
                onChange={() => handleUserSetCheckbox(setting.key)}
              />
              <span>{setting.label}</span>
            </label>
          </div>
        ))}
        <div className="settings-item" onClick={()=>{startColorPick('startColor')}}>
          <label className="settings-label" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <span  className="settings-color-indicator" style={{
                backgroundColor: userSetsRef.current.startColor || '#000000FF',
              }}
            ></span>
            <span>{t('settings.start_color')}</span>
          </label>
        </div>

        <div className="settings-item" onClick={()=>{startColorPick('startBgColor')}}>
          <label className="settings-label" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <span  className="settings-color-indicator" style={{
                backgroundColor: userSetsRef.current.startBgColor || '#000000FF',
              }}
            ></span>
            <span>{t('settings.start_bg_color')}</span>
          </label>
        </div>
        {
          userSetsRef.current.moder && (
            <button className="wide-button" onClick={moderEdit}>
              {t('settings.moderate')}
            </button>
          )
        }
        <button className="wide-button" onClick={
          ()=>{hostAction('sendInPm', null, (result)=>{
            result && window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')
        })}
        }>
          {t('settings.send_in_pm')}
        </button>
        <button className="wide-button" onClick={() => {
          setShowSettings(false);
          delayPremiumMenu('menu');
        }}>
          {t('settings.premium')}
        </button>
        <button className="gray-button" onClick={() => setShowSettings(false)}>
          {t('settings.back')}
        </button>
      </div>

      { userColorView && (
          <>
            <div className="overlay-background" style={{zIndex: 1400}} onClick={endColorPick}></div>
            <div className="settings-color-picker-container">
              <HexAlphaColorPicker 
                color={viewedColor} 
                onChange={onColorPicking}
              />
              <button className="wide-button" onClick={endColorPick} style={{marginTop: '10px'}}>
                {t('settings.set_color')}
              </button>
            </div>
          </>
      ) }

    </>
  );
}

export default SettingsOverlay;
