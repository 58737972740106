// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.brush-menu {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 160px;
  text-align: center;
  background-color: var(--tg-theme-bg-color);
  padding: 20px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brush-title {
  color: var(--tg-theme-button-color); /* Синий цвет */
  font-size: 24px;
  margin-bottom: 15px;
  margin-top: -5px;
}


.brush-icon {
  width: 25px;
  height: 25px;
  margin: 2px;
  margin-right: 8px;
  color: var(--tg-theme-button-color); /* Синий цвет */
}

.brush-option {
  background: none;
  color: var(--tg-theme-text-color);
  margin: 3px;
  padding: 5px;
  cursor: pointer;
  opacity: 0.7;
  width: 90%;
}

.brush-option.active {
  color: var(--tg-theme-button-color);
  background-color: rgba(115, 185, 255, 0.2);
  border-radius: 12px;  /* Добавлено для фона активной кнопки */
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/board/helpers/BrushMenu.css"],"names":[],"mappings":";AACA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,YAAY;EACZ,kBAAkB;EAClB,0CAA0C;EAC1C,aAAa;EACb,yCAAyC;EACzC,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,mCAAmC,EAAE,eAAe;EACpD,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;;AAGA;EACE,WAAW;EACX,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,mCAAmC,EAAE,eAAe;AACtD;;AAEA;EACE,gBAAgB;EAChB,iCAAiC;EACjC,WAAW;EACX,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,mCAAmC;EACnC,0CAA0C;EAC1C,mBAAmB,GAAG,uCAAuC;EAC7D,UAAU;AACZ","sourcesContent":["\r\n.brush-menu {\r\n  position: fixed;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  width: 160px;\r\n  text-align: center;\r\n  background-color: var(--tg-theme-bg-color);\r\n  padding: 20px;\r\n  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);\r\n  border-radius: 12px;\r\n  z-index: 1100;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n}\r\n\r\n.brush-title {\r\n  color: var(--tg-theme-button-color); /* Синий цвет */\r\n  font-size: 24px;\r\n  margin-bottom: 15px;\r\n  margin-top: -5px;\r\n}\r\n\r\n\r\n.brush-icon {\r\n  width: 25px;\r\n  height: 25px;\r\n  margin: 2px;\r\n  margin-right: 8px;\r\n  color: var(--tg-theme-button-color); /* Синий цвет */\r\n}\r\n\r\n.brush-option {\r\n  background: none;\r\n  color: var(--tg-theme-text-color);\r\n  margin: 3px;\r\n  padding: 5px;\r\n  cursor: pointer;\r\n  opacity: 0.7;\r\n  width: 90%;\r\n}\r\n\r\n.brush-option.active {\r\n  color: var(--tg-theme-button-color);\r\n  background-color: rgba(115, 185, 255, 0.2);\r\n  border-radius: 12px;  /* Добавлено для фона активной кнопки */\r\n  opacity: 1;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
