
import './locales/i18n'
import chroma from 'chroma-js';

import React, { useEffect } from 'react';
import { GameProvider } from './contexts/GameContext';
import BoardProviders from './components/board/BoardProviders';
import Lobby from './components/lobby/Lobby';

import { applyThemeColors, applyAdditionalColors, applyAdditionalShades } from './utils/themeColors';

const RootComponent = () => {

  useEffect(() => {
    const preventTouch = (e) => {
      if (e.target.closest('.widthSlider')) { return; }
      if (e.target.closest('.premium-menu')) { return; }
      e.preventDefault();
    };

    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('touchmove', preventTouch, { passive: false });
    document.addEventListener('contextmenu', handleContextMenu);

    // Очистка обработчика
    return () => {
      document.removeEventListener('touchmove', preventTouch)
      document.removeEventListener('contextmenu', handleContextMenu);
    };

  }, []);

  return (
    <BoardProviders />
  );
};



function App() {

  // Применяем основные цвета темы
  applyThemeColors(window.Telegram?.WebApp?.themeParams || {});
  // Применяем дополнительные цвета для нужных CSS-переменных
  applyAdditionalShades('--tg-theme-secondary-bg-color', 1.5);
  applyAdditionalShades('--tg-theme-button-color', 1.2);

  const REACT_APP_VERSION = process.env.REACT_APP_VERSION;
  const testing = REACT_APP_VERSION === 'local' && true;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const startParam = urlParams.get('tgWebAppStartParam');

  return (
    <>
     {(startParam || testing) ?  (<RootComponent />)  : (<Lobby />) }
    </>
  );
  // return (
  //   <GameProvider>
  //     <RootComponent />
  //   </GameProvider>
  // );
}

export default App;
