import './ControlButtons.css'; // Подключаем стили

import React, { useState, useEffect } from 'react';

import { FaSprayCan, FaPencilAlt, FaFeather, FaPaintBrush, FaEraser, FaFillDrip, FaEyeDropper, FaPalette, FaUndo, FaRedo, FaTimes, FaArrowsAltV, FaPencilRuler, FaBrush } from 'react-icons/fa';

import ColorPalettePopup from './Palette/ColorPalettePopup.js';
import PremiumGrayscalePalette from './Palette/PremiumGrayscalePalette.js';
import GrayscalePicker from './Palette/GrayscalePicker.js';
import BrushSizeControl from './BrushSizeControl';

import SoftnessControl from './SoftnessControl.js'; 
import BrightnessControl from './BrightnessControl'; 
import OpacityControl from './OpacityControl'; 
import ColorIndicator from './ColorIndicator';

// import SaturationControl from './SaturationControl'; 
// import HueControl from './HueControl';

import CircleSwitch from "./CircleSwitch/CircleSwitch.tsx";


function ControlButtons({
  gameContext,
  canvasContext,
  brushContext,
  drawMethods,
}) {

    const { 
      gameInfo,
      userSetsRef,

      premiumFeaturesRef,
     } = gameContext;

    const { 
      zoomFactor,
     } = canvasContext;

    const { 
      color, setTheColorFun, 
      
      showPalette, setShowPalette,
      premiumGrayscale,
      activeTool,

      isPipettePicking,
      pickingPlaceRef,
      brushIndicatorFrame,

      brushTypeRef,
     } = brushContext;

    const { 
      changeTool,
      undoLastAction, 
      redoLastAction,
      confirmClearCanvas, 
     } = drawMethods;

     const huawei = userSetsRef.current.huawei;


    const BrushIcon = {
      spray: FaSprayCan,
      pencil: FaPencilAlt,
      feather: FaFeather,
      plain: FaPaintBrush,
    }[brushTypeRef.current] || FaPaintBrush;

    const brushPath = null;
    // const brushPath = brushTypeRef.current === 'feather' ? null : "icons/pen.svg";

    return (
      <div className='controls-frame'>

        {/* Верхний ряд с инструментами */}
        <div className='controls-row'>

            {
              gameInfo?.mode === 'sprint' ? (
                <CircleSwitch
                  icon={<BrushIcon />}
                  path={brushPath}
                  huawei={huawei}
                  isActive={activeTool === "brush"}
                  onClick={() => changeTool("brush")}
                />
              ) : 
              (
                <SoftnessControl 
                  canvasContext={canvasContext}
                  gameContext={gameContext}
                  brushContext={brushContext} 
                  drawMethods={drawMethods} 
                  path={brushPath}
                  icon={<BrushIcon />}
                  huawei={huawei}
                />
              )
            }

            <CircleSwitch
              icon={<FaEraser />}
              path="icons/eraser.svg"
              huawei={huawei}
              isActive={activeTool === "eraser"}
              onClick={() => changeTool("eraser")}
            />

            <OpacityControl 
                canvasContext={canvasContext}
                brushContext={brushContext} 
                drawMethods={drawMethods} 

                path="icons/filler.svg"
                icon= {<FaFillDrip />}
                huawei={huawei}
                available={premiumFeaturesRef.current.includes('filler') && !userSetsRef.current.premiumOff}
              />

            {/* <CircleSwitch
									path="icons/filler.svg"
									isActive={activeTool === "filler"}
									onClick={() => changeTool("filler")}
								/> */}

            <BrightnessControl 
              canvasContext={canvasContext}
              brushContext={brushContext} 
              drawMethods={drawMethods} 

              path="icons/pipette.svg"
              icon= {<FaEyeDropper />}
              huawei={huawei}
              available={premiumFeaturesRef.current.includes('pipette') && !userSetsRef.current.premiumOff}
            />

            {/* <CircleSwitch
									path="icons/pipette.svg"
                  huawei={huawei}
									isActive={activeTool === "pipette"}
									onClick={() => changeTool("pipette")}
								/> */}
            <CircleSwitch
              id="paletteToggleButton" 
              path="icons/palette.svg"
              huawei={huawei}
              icon={<FaPalette />}
              isActive={showPalette}
              color={color}
              onClick={() => setShowPalette(showPalette=>!showPalette)}
            />      

        </div>
        
        {/* Нижний ряд с управляющими кнопками */}
        <div className='controls-row'>

        <CircleSwitch
          path="icons/undo.svg"
          icon={<FaUndo />}
          huawei={huawei}
          onClick={(e) => {
            undoLastAction();
            setShowPalette(false);
          }}
        />

        <CircleSwitch
          path="icons/redo.svg"
          icon={<FaRedo />}
          huawei={huawei}
          onClick={(e) => {
            redoLastAction();
            setShowPalette(false);
          }}
        />

        <CircleSwitch
          path="icons/xmark.svg"
          icon={<FaTimes />}
          huawei={huawei}
          onClick={(e) => {
            confirmClearCanvas();
            setShowPalette(false);
          }}
        />
          <BrushSizeControl 
            canvasContext={canvasContext}
            path="icons/pen-ruler.svg"
            icon={<FaPencilRuler />}
            huawei={huawei}
            brushContext={brushContext} 
            canvasContext={canvasContext} 
          />
        </div>

    {/* userSetsRef.current.premiumOff */}

    {showPalette && (
      (!premiumFeaturesRef.current.includes('colors')
      || userSetsRef.current.premiumOff) ? (
      <GrayscalePicker
        gameContext={gameContext}
        brushContext={brushContext}
        canvasContext={canvasContext}
      />
      ) : premiumGrayscale ? (
        <PremiumGrayscalePalette
          gameContext={gameContext}
          brushContext={brushContext}
          canvasContext={canvasContext}
        />
      ) : (
        <ColorPalettePopup
          gameContext={gameContext}
          brushContext={brushContext}
          canvasContext={canvasContext}
        />
      )
      )}
      {isPipettePicking && (
          <ColorIndicator
            canvasContext={canvasContext}
            color={color}
            position={{
              x: pickingPlaceRef.current.x + 0,
              y: pickingPlaceRef.current.y + 10,
            }}
            visibleLineWidth={55}
            brushIndicatorFrame={brushIndicatorFrame}
          />
      )}
</div>
    );
}

export default ControlButtons;
