// GameContext.js
import React, { createContext, useContext, useState, useRef } from 'react';

import useWebSocket from '../hooks/connection/useWebSocket';
import useTelegram from '../hooks/connection/useTelegram'; 
import useGameLoader from '../hooks/connection/useGameLoader';  // Подключаем новый хук
import { saveDrawing } from '../hooks/connection/saveDrawing'; 
import { imageDataToPNG, updatePngCache } from '../hooks/connection/savePng'; 
import { useUpdateGame } from '../hooks/game/useUpdateGame';  
import { usePremium } from '../hooks/game/usePremium';  
import { useGameSettings } from '../hooks/game/useGameSettings';  

const GameContext = createContext();

export const useGameContext = () => useContext(GameContext);

export const GameProvider = ({ children }) => {

  const imageCache = useRef(new Map());

  const [forcer, setForcer] = useState(0);
  const forceRender = () => { setForcer(i => i + 1);};

  const [gameInfo, setGameInfo] = useState(null);
  const [gameIsLoaded, setGameIsLoaded] = useState(null);
  const [premiumIsLoaded, setPremiumIsLoaded] = useState(null);
  const [strokesAreLoaded, setStrokesAreLoaded] = useState(null);
  const gameInfoRef = useRef(null);

  const [gameError, setGameError] = useState();
  const [canDraw, setCanDraw] = useState(true);

  const [continueDrawing, setContinueDrawing] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showWordSettings, setShowWordSettings] = useState(false);
  const [showBrushMenu, setShowBrushMenu] = useState(false);

  const [showPremiumMenu, setShowPremiumMenu] = useState(false);
  const [showPremiumInfo, setShowPremiumInfo] = useState(null);
  const premiumSubsRef = useRef([]);
  const premiumFeaturesRef = useRef([]);

  const [showModeHelp, setShowModeHelp] = useState(true);

  const userSetsRef = useRef({ colors: [],});
  const groupSetsRef = useRef({});
  const botSetsRef = useRef({});

  const connectionStatusRef = useRef({
    lastSaveTime: 0,
    lastPreviewTime: 0,
    lastConnectionErrorTime: 0,
  });


  const {
    startParam, 
    gameId,
    groupId,
    socketPortNum,
    telegramUserId,
    languageCode,

    activeUserIdRef,
    moderatorIdRef,
    moderatorModeRef,

    isValid, setIsValid,
    isChecked,
    isHacker,

    hash,
    initData,
    dataCheckString,

   } = useTelegram({
    setShowSettings,
   });

   
  const { 
    socketRef, 
    socketStatus,
    PORT,
   } = useWebSocket({
    gameId, 
    groupId, 
    socketPortNum,
    
    telegramUserId, 
    initData,
    setIsValid,
   });


  useGameLoader({
    socketRef, 
    socketStatus, 
    gameId, 
    groupId, 
    telegramUserId,
    moderatorModeRef,

    activeUserIdRef,
    moderatorIdRef,

    isValid,
    gameInfoRef, 
    gameInfo, 
    setGameInfo, 
    setGameIsLoaded,

    setGameError,
    setCanDraw,

    userSetsRef,
    groupSetsRef,
    botSetsRef,

    imageCache,
    updatePngCache,
  });

  useUpdateGame({
    socketRef, 
    socketStatus, 
    gameInfoRef, 
    setGameInfo, 
    connectionStatusRef,
  });

  usePremium({
    gameInfo,
    gameIsLoaded,
    setPremiumIsLoaded,

    userSetsRef,
    groupSetsRef,
    
    premiumSubsRef,
    premiumFeaturesRef,
  });

  const {
    changeWord,
    activateHints,
    openLetter,
    saveUserSets,
    saveGameSets,
    groupRedraw,
    askGroupPremium,
    hostAction,
  } = useGameSettings({
    gameId,
    telegramUserId,
    activeUserIdRef,
    
    socketRef, 
    setGameInfo,
    gameInfoRef, 

    userSetsRef,
    forceRender,
  })

  const delayPremiumMenu = (value, delay = 50)=>{
    setTimeout(() => {
      setShowPremiumMenu(value);
    }, delay);
  }

  return (
    <GameContext.Provider value={{ 
      forceRender,

      gameInfo, setGameInfo,
      gameInfoRef,
      gameIsLoaded,
      premiumIsLoaded,

      strokesAreLoaded, setStrokesAreLoaded,

      gameError,

      canDraw,
      continueDrawing, setContinueDrawing,
      showSettings, setShowSettings,
      showWordSettings, setShowWordSettings,
      showBrushMenu, setShowBrushMenu,

      showPremiumMenu, setShowPremiumMenu, delayPremiumMenu,
      showPremiumInfo, setShowPremiumInfo,
      premiumSubsRef,
      premiumFeaturesRef,

      showModeHelp, setShowModeHelp,

      connectionStatusRef,

      startParam, 
      gameId,
      telegramUserId,
      activeUserIdRef,
      
      groupId,
      moderatorIdRef,
      moderatorModeRef,
      languageCode,

      setCanDraw,

      isValid, setIsValid,
      isChecked,
      isHacker,
      hash,
      initData,
      dataCheckString,

      socketRef,
      socketStatus,
      PORT,
      saveDrawing,
      imageDataToPNG,
      imageCache,

      changeWord,
      activateHints,
      openLetter,
      groupRedraw,
      askGroupPremium,
      hostAction,
      
      userSetsRef,
      saveUserSets,
      saveGameSets,

      groupSetsRef,
      botSetsRef,


    }}>
      {children}
    </GameContext.Provider>
  );
};
