// BrushContext.js
import React, { createContext, useContext, useState, useRef, useEffect } from 'react';
import chroma from 'chroma-js';
import { useGameContext } from './GameContext';


const BrushContext = createContext();

export const BrushProvider = ({ children }) => {

  const gameContext = useGameContext();
  const { 
    userSetsRef,
    premiumFeaturesRef,
   } = gameContext;

  const [activeTool, setActiveTool] = useState("brush"); // По умолчанию активен инструмент кисти
  const activeToolRef = useRef(activeTool); 
  const previousToolRef = useRef(activeTool);

  const [brushType, setBrushType] = useState("plain");
  const brushTypeRef = useRef(brushType); 
  const brushHistoryRef = useRef([]); 

  const [color, setColor] = useState('#000000CC');
  const colorRef = useRef(color);
  const brushColorRef = useRef(color);
  const eraserColorRef = useRef('#FFFFFFFF');
  const [grayPaletteColor, setGrayPaletteColor] = useState(color);


  const quickColorChangeTimeRef = useRef(0);
  const lastColorsRef = useRef([]);

  const isPalletFirstOpenRef = useRef(true);
  const wasPaletteOnceOpenedRef = useRef(false);

  // const lastColorsRef = useRef([
  //   '#000000CC', // Черный с прозрачностью
  //   '#FFFFFFCC', // Белый с прозрачностью
  //   '#808080CC', // Серый с прозрачностью
  //   // '#ADD8E6CC', // Бледно-голубой
  //   // '#FFC0CB99', // Бледно-розовый, уменьшенная насыщенность для более пастельного оттенка
  //   // '#90EE90CC'  // Бледно-зеленый
  // ]);
  const previousColorRef = useRef(color);

  const [lineWidth, setLineWidth] = useState(7);
  const lineWidthRef = useRef(lineWidth);
  const brushSizeRef = useRef(7);
  const eraserSizeRef = useRef(25);

  const previousLineWidthRef = useRef(lineWidth);
  const [visibleLineWidth, setVisibleLineWidth] = useState(lineWidth);

  const [softness, setSoftness] = useState(0.0);
  const softnessRef = useRef(softness);


  const [hotMouseActive, setHotMouseActive] = useState(false);
  const hotMouseStartPositionRef = useRef({x: 0, y: 0});

  const [isResizingBrush, setIsResizingBrush] = useState(false);
  const [isChangingBrightness, setIsChangingBrightness] = useState(false);
  const [isChangingOpacity, setIsChangingOpacity] = useState(false);
  const [isChangingSoftness, setIsChangingSoftness] = useState(false);

  const lastBrushChangeTime = useRef(0);

  const shiftIsDownRef = useRef(false);
  const altIsDownRef = useRef(false);
  const controlIsDownRef = useRef(false);
  const spaceIsDownRef = useRef(false);
  const tabIsDownRef = useRef(false);

  const [showPalette, setShowPalette] = useState(false);
  const showPaletteRef = useRef(false);
  const [premiumGrayscale, setPremiumGrayscale] = useState(false);

  const [isPipettePicking, setIsPipettePicking] = useState(false);
  const [pickingPlace, setPickingPlace] = useState({x: 0, y: 0});
  const pickingPlaceRef = useRef({x: 0, y: 0});

  const longPressTimeoutRef = useRef();
  const shapeStartPointRef = useRef();
  const drawingShapeRef = useRef(false);
  const lastShapeMadeTimeRef = useRef(0);


  const [multiplierTextColor, setMultiplierTextColor] = useState('black');
  const [brushIndicatorFrame, setBrushIndicatorFrame] = useState('none');

  const setTheColorFun = (colorInput) => {
  
    let newColor = typeof colorInput === 'function' ? colorInput(color) : colorInput;

    if (
      !premiumFeaturesRef.current.includes('colors') 
      || userSetsRef.current.premiumOff
      ) {
      // newColor= chroma(newColor).set('hsl.s', 0).hex('rgba');
      const alpha = chroma(newColor).alpha(); // Сохраняем прозрачность текущего цвета
      newColor = chroma(newColor).set('hsv.s', 0).alpha(alpha).hex('rgba'); 
    }

    if (
      activeToolRef.current === 'eraser' 
      || (activeToolRef.current === 'pipette' && previousToolRef.current === 'eraser')
      ) {
      eraserColorRef.current = newColor;
    } else {
      brushColorRef.current = newColor;
    }
    colorRef.current = newColor;
    setColor(newColor);
  };

  const setTheSizeFun = (sizeInput) => {
    const newSize = typeof sizeInput === 'function' ? sizeInput(lineWidth) : sizeInput;

    if (activeToolRef.current === 'eraser') {
      eraserSizeRef.current = newSize;
    } else {
      brushSizeRef.current = newSize;
    }
    setLineWidth(newSize);
  };


  const handleLastColors = (newColor = colorRef.current) => {
    setColor(newColor);  // Установка нового цвета
    colorRef.current = newColor;  // Обновление текущего цвета в рефе
  
    const filteredColors = lastColorsRef.current.filter(color => color !== newColor);
    filteredColors.push(newColor);

    lastColorsRef.current = filteredColors;
  };

  function adjustColor(cssVariable, baseColor) {
    function getCSSVariableValue(variable) {
      return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
    }
  
    const cssColor = getCSSVariableValue(cssVariable);
    const cssChroma = chroma(cssColor);
    const baseChroma = chroma(baseColor);
    const [, s, l] = cssChroma.hsl();
    const [h, , ] = baseChroma.hsl();
    const resultColor = chroma.hsl(h, s, l);
    return resultColor.css();
  }

  function blendColors(cssVariable, baseColor, opacity = 0.2) {
    function getCSSVariableValue(variable) {
      return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
    }
    const cssColor = getCSSVariableValue(cssVariable);
    const cssChroma = chroma(cssColor);
    const baseChroma = chroma(baseColor);
    const blendedColor = chroma.mix(cssChroma, baseChroma, opacity, 'rgb');
    return blendedColor.css();
  }
  

  // Обновляем ref при изменении соответствующих значений
  useEffect(() => {
    colorRef.current = color;
    document.documentElement.style.setProperty('--brush-color', color);
    document.documentElement.style.setProperty('--brush-solid-color', chroma(color).alpha(1).hex('rgba'));
  }, [color]);

  useEffect(() => {
    lineWidthRef.current = lineWidth;
  }, [lineWidth]);

  useEffect(() => {
    softnessRef.current = softness ;
  }, [softness]);
  
  useEffect(() => {
    brushTypeRef.current = brushType ;
  }, [brushType]);

  useEffect(() => {
    activeToolRef.current = activeTool;

    if (activeTool === 'eraser') {
      setColor(eraserColorRef.current); 
      setLineWidth(eraserSizeRef.current); 
    } else if (['brush', 'filler'].includes(activeTool)) {
      setColor(brushColorRef.current);
      setLineWidth(brushSizeRef.current);
    }
  }, [activeTool]);

  useEffect(() => {
    // Проверяем, стало ли значение showPalette равным false
    if (!showPalette) {
      handleLastColors();  // Вызываем функцию для обработки последних цветов
    }
  }, [showPalette]);  // Зависимость от showPalette

  // Обводка индикатора кисти
  useEffect(() => {

    const chromaColor = chroma(color); // текущий цвет
    const currentColorLuminance = chromaColor.luminance();
  
    const bgColor = getComputedStyle(document.documentElement).getPropertyValue('--tg-theme-bg-color');
    const chromaBgColor = chroma(bgColor);
    const bgColorLuminance = chromaBgColor.luminance();

    const whiteLuminance = chroma('#FFFFFFFF').luminance();
    const blackLuminance = chroma('#00000000').luminance();
  
    const isLuminanceClose = Math.abs(currentColorLuminance - bgColorLuminance) < 0.05;

    const isLuminanceCloseToWhite = Math.abs(currentColorLuminance - whiteLuminance) < 0.05;
    const isLuminanceCloseToBlack = Math.abs(currentColorLuminance - blackLuminance) < 0.05;

    const textColorCss = getComputedStyle(document.documentElement).getPropertyValue('--tg-theme-text-color').trim();
    const rgbaTextColor = chroma(textColorCss).alpha(0.3).css(); // Преобразование в rgba с прозрачностью 0.5

    if (activeTool === 'eraser') {

      const brushIndicatorFrameValue = 
      isLuminanceCloseToWhite ? `0 0 0 1px rgba(30, 30, 30, 0.2)` :
      isLuminanceCloseToBlack ? `0 0 0 1px rgba(175, 175, 175, 0.35)` :
      (isLuminanceClose || chromaColor.alpha() < 0.1)
        ? `0 0 0 1px ${rgbaTextColor}` // используем переменную CSS для цвета текста
        : `0 0 0 1px rgba(30, 30, 30, 0.2)`;
        // : 'none';
      setBrushIndicatorFrame(brushIndicatorFrameValue);

    } else {

      const brushIndicatorFrameValue = 
      isLuminanceCloseToWhite ? `0 0 0 1px rgba(30, 30, 30, 0.2)` :
      (isLuminanceClose || chromaColor.alpha() < 0.1)
      ? `0 0 0 1px ${rgbaTextColor}` // используем переменную CSS для цвета текста
      : 'none';
      setBrushIndicatorFrame(brushIndicatorFrameValue);
    }

    // Определяем цвет текста в зависимости от яркости текущего цвета
    const textColor = (currentColorLuminance > 0.9) || (chromaColor.alpha() < 0.1) ? '#222222' : '#EEEEEE';
    setMultiplierTextColor(textColor);
  
  }, [color, activeTool]);

  useEffect(() =>{
    showPaletteRef.current = showPalette;
  }, [showPalette])

  // Автосмена черного при открытии палитры
  useEffect(() => {

    const currentColor = chroma(color);
    let currentHex = (currentColor.hex()).slice(0, 7);

    if (showPalette) {

      if (isPalletFirstOpenRef.current) {

        wasPaletteOnceOpenedRef.current = true;

        // Проверяем, является ли текущий цвет черным (любая прозрачность)
        if (currentHex === '#000000') {
          const alpha = currentColor.alpha(); // Получаем текущую прозрачность
          const newColor = chroma('#ff2221').alpha(alpha).hex('rgba'); // Максимально красный с той же прозрачностью
          previousColorRef.current = color;
          setColor(newColor);
        }

      }

    } else {

      if (
        wasPaletteOnceOpenedRef.current 
        && isPalletFirstOpenRef.current
        ) {

        isPalletFirstOpenRef.current = false;
        
        // Проверяем, является ли текущий цвет черным (любая прозрачность)
        if (currentHex === '#ff2221') {
          setColor(previousColorRef.current);
        }
      }

    }

  }, [showPalette]); 


  return (
    <BrushContext.Provider value={{ 
      activeTool, setActiveTool,
      activeToolRef,
      previousToolRef,

      brushType, setBrushType,
      brushTypeRef,
      brushHistoryRef,

      color, setTheColorFun,
      colorRef,
      brushColorRef,
      eraserColorRef,
      grayPaletteColor, setGrayPaletteColor,
      
      quickColorChangeTimeRef,
      previousColorRef,
      
      lastColorsRef,
      handleLastColors,

      setTheSizeFun,
      lineWidth, setLineWidth: setTheSizeFun,
      lineWidthRef,
      previousLineWidthRef,
      visibleLineWidth, setVisibleLineWidth,

      softness, setSoftness,
      softnessRef,

      hotMouseActive, setHotMouseActive,
      hotMouseStartPositionRef,

      isResizingBrush, setIsResizingBrush,
      isChangingBrightness, setIsChangingBrightness,
      isChangingOpacity, setIsChangingOpacity,
      isChangingSoftness, setIsChangingSoftness,

      lastBrushChangeTime,

      shiftIsDownRef,
      altIsDownRef,
      controlIsDownRef,
      spaceIsDownRef,
      tabIsDownRef,

      showPalette, setShowPalette,
      showPaletteRef,
      premiumGrayscale, setPremiumGrayscale,

      isPipettePicking, setIsPipettePicking,
      pickingPlace, setPickingPlace,
      pickingPlaceRef,

      longPressTimeoutRef,
      shapeStartPointRef,
      drawingShapeRef,
      lastShapeMadeTimeRef,

      multiplierTextColor, setMultiplierTextColor,
      brushIndicatorFrame, setBrushIndicatorFrame,

      adjustColor,
      blendColors,

       }}>
      {children}
    </BrushContext.Provider>
  );
};

export const useBrushContext = () => useContext(BrushContext);