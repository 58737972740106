// WordChangeOverlay.js

import React from 'react';
import { FaSprayCan, FaPencilAlt, FaFeather, FaPaintBrush, FaBrush } from 'react-icons/fa';

import './BrushMenu.css'; // Подключаем стили
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';


function BrushMenu({
  gameContext,
  brushContext,
  drawingContext,
}) {

  const { t } = useTranslation();

  const {
    gameInfoRef,
    setShowBrushMenu,

    premiumFeaturesRef,
    userSetsRef,

    delayPremiumMenu,
  } = gameContext;

  const { 
    setBrushType,
    brushTypeRef,

    brushHistoryRef,
    setTheColorFun,

    colorRef,
    setSoftness,
   } = brushContext;

  const {
    lastActionTimeRef,
  } = drawingContext;

  const game = gameInfoRef.current;

  const brushes = [
    {
      key: 'plain',
      label: t('brush.plain.name'),
      icon: FaPaintBrush,
    },
    // {
    //   key: 'soft',
    //   label: t('brush.soft.name'),
    // },
    {
      key: 'spray',
      label: t('brush.spray.name'),
      icon: FaSprayCan,
    },
    {
      key: 'feather',
      label: t('brush.feather.name'),
      icon: FaFeather,
    },
    {
      key: 'pencil',
      label: t('brush.pencil.name'),
      icon: FaPencilAlt,
    },
  ]

  const handleOverlayClick = () =>{
    if (lastActionTimeRef.current < Date.now() - 300) {
      setShowBrushMenu(false); 
    }
  }

  const handleSwitchBrush = (newBrushType) => {

    if (newBrushType !== 'plain' && 
      (!premiumFeaturesRef.current.includes('softBrush') 
      || userSetsRef.current.premiumOff)
      ) {
      setShowBrushMenu(false); 
      delayPremiumMenu('menu');
    } else {

      const previousBrush = brushTypeRef.current;
      
      if (previousBrush !== newBrushType) {
        window.Telegram?.WebApp?.HapticFeedback?.notificationOccurred('success')
      }
      brushTypeRef.current = newBrushType;
      setBrushType(newBrushType);

      if (newBrushType === 'pencil' && !brushHistoryRef.current.includes('pencil') && previousBrush !== 'pencil') {
        const currentColor = chroma(colorRef.current);
        const currentAlpha = currentColor.alpha();
        if (currentAlpha > 0.8) {
          const adjustedColor = currentColor.alpha(0.6).hex('rgba');
          setTheColorFun(adjustedColor)
        }
      }

      if (newBrushType === 'spray') {
        setSoftness(0.3)
      } else {
        setSoftness(0)
      }


      brushHistoryRef.current.push(newBrushType);
      setShowBrushMenu(false); 

    }

  };


  return (
    <>
      <div className="overlay-background" onClick={handleOverlayClick}></div>
      <div className="brush-menu">

        <span className="brush-title">{t('brush.title')}</span>

          {brushes.map((brush) => (
            <div key={brush.key} 
            className={`brush-option ${brushTypeRef.current === brush.key ? 'active' : ''}`}
            
            onClick={()=>handleSwitchBrush(brush.key)}>
              <label className="settings-label">
                <brush.icon className='brush-icon'/>
                <span> {brush.label}</span>
              </label>
            </div>
        ))}
        
      </div>
    </>
  );
}

export default BrushMenu;
