// useRedraw.js
import { useCallback, useEffect, } from 'react';

import { useGameContext  } from '../../contexts/GameContext';
import { useDrawingContext  } from '../../contexts/DrawingContext';
import { useBrushContext  } from '../../contexts/BrushContext';
import { useHistoryContext  } from '../../contexts/HistoryContext';

import { createFill, drawFill } from './floodFill';
import { showZoomed, freeMemory } from './imageCached';

import { drawTaperingStroke } from './brushes/tapering';
import { drawPencilStroke } from './brushes/pencil';

export const useRedraw = () => {

  const gameContext = useGameContext();
  const canvasContext = useDrawingContext();
  const brushContext = useBrushContext();
  const HistoryContext = useHistoryContext();

  const {
    imageCache,
    imageDataToPNG,
    gameInfoRef,

    strokesAreLoaded,
  } = gameContext;

  const {
    canvasDimensionsRef,

    contextRef,
    canvasRef,
    softCanvasRef,
    softContextRef,

    canvasScaleRef,
  
    zoomFactor, 
    zoomFactorRef,
    maxZoomFactorRef,
    isDrawingRef,
  
  } = canvasContext;

  const {
    lineWidth,
    setVisibleLineWidth,
  } = brushContext;

  const {
    userStrokesRef,
    redrawer,
  } = HistoryContext;


  function drawBackground({ context, color }) {
    context.save();
    context.fillStyle = color;
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    context.restore();
  }

  function drawStroke(stroke, context) {

    const {
      color,
      lineWidth,
      points,
      softness,
      brush,
      time,
    } = stroke;

    if (brush === 'feather' && points.length >= 2) {
      return drawTaperingStroke({
        color,
        lineWidth,
        points,
      }, context);

    } else if (brush === 'pencil') {
      return drawPencilStroke({
        color,
        lineWidth,
        points,
        time,
      }, context);
    }

    if (softness > 0) {

      const softCanvas = softCanvasRef.current;
      const softContext = softContextRef.current;

      softContext.save();

      // Применяем размытие
      const blurSize = lineWidth * softness;
      softContext.filter = `blur(${blurSize}px)`;
  
      // Рисуем штрих на временном канвасе
      drawPlainStroke({
        context: softContext,
        color,
        lineWidth,
        points,
      });
  
      // Накладываем временный канвас на основной
      context.drawImage(softCanvas, 0, 0);
      softContext.clearRect(0, 0, softCanvas.width, softCanvas.height);
      softContext.restore();


    } else {
      // Рисуем штрих напрямую на основном канвасе, если softness равен 0
      drawPlainStroke({
        context,
        color,
        lineWidth,
        points,
      });
    }
  }
  
  function drawPlainStroke({
    context, 
    color, 
    lineWidth, 
    points,
  }) {

    if (points.length === 0) return;
    context.beginPath();
    context.strokeStyle = color;
    context.lineWidth = lineWidth; 
    context.lineCap = 'round'; 
    context.lineJoin = 'round';

    let lastPoint = points[0];
    if (Array.isArray(lastPoint)) {} else {lastPoint = [lastPoint.x, lastPoint.y]}

    if (points.length === 1) {

      let point = points[0];
      if (Array.isArray(point)) {} else {point = [point.x, point.y]}
          
      // Для одиночной точки рисуем круг
      context.beginPath();
      context.arc(
        point[0], 
        point[1], 
        lineWidth / 2, 
        0, 
        Math.PI * 2
        );
      context.fillStyle = color;
      context.fill();
  
    } else if (points.length === 2) {

      // Рисуем линию между двумя точками
      context.moveTo(
        lastPoint[0], 
        lastPoint[1],
      );
      let secondPoint = points[1];
      if (Array.isArray(secondPoint)) {} else {secondPoint = [secondPoint.x, secondPoint.y]}

      context.lineTo(
        secondPoint[0], 
        secondPoint[1],
      );
      context.stroke();

    } else if (points.length > 2) {

      for (let i = 1; i < points.length; i++) {
        let currentPoint = points[i];
        if (Array.isArray(currentPoint)) {} else {currentPoint = [currentPoint.x, currentPoint.y]}

        const midPoint = [
          (lastPoint[0] + currentPoint[0]) / 2,
          (lastPoint[1] + currentPoint[1]) / 2,
        ];
        context.quadraticCurveTo(
          lastPoint[0], 
          lastPoint[1], 
          midPoint[0], 
          midPoint[1]
        );
        lastPoint = currentPoint;
      }

      context.lineTo(
        lastPoint[0], 
        lastPoint[1],
        );
      context.stroke();

    }

  }

  const redrawCanvas = useCallback(() => {

    const context = contextRef.current;
    const canvas = canvasRef.current;
  
    if (!context || !canvas) return;
  
    context.save(); // Сохраняем текущее состояние контекста
    context.setTransform(1, 0, 0, 1, 0, 0); // Сбрасываем трансформацию
    context.clearRect(0, 0, canvas.width, canvas.height); // Очищаем канвас
    context.restore(); // Восстанавливаем состояние контекста
    
    context.fillStyle = "white";
    context.fillRect(0, 0, canvas.width, canvas.height);
  
    const needToRender = prepareStrokes();
    
    let hasSoftBrush = false;
    let hasTaperingBrush = false;
    let hasPencilBrush = false;
    maxZoomFactorRef.current = 20;

    needToRender.forEach((stroke, i) => {

      if (imageCache.current.get(stroke.time)) {
        showZoomed({
          stroke,
          canvas: canvasRef.current, 
          imageCache,
        })
      } else if (stroke.type === 'background') {
        drawBackground({ context, color: stroke.color })
      } else if (stroke.type === 'fill') {
        showFIll ({
          canvas: canvasRef.current, 
          stroke, 
          needToRender, 
          i,
        })
      } else if (stroke.type === 'stroke') {
        drawStroke(stroke, context);

        if (stroke.softness > 0) { hasSoftBrush = true; }
        if (stroke.brush === 'feather') { hasTaperingBrush = true;}
        if (stroke.brush === 'pencil') { hasPencilBrush = true;}
      }
    });

    let amountForCache = (hasSoftBrush || hasTaperingBrush || hasPencilBrush) ? 5 : 100;

    if (needToRender.length >= amountForCache) {
      prepareCache (needToRender);
      const lastStroke =  needToRender[needToRender.length - 1];
      freeMemory ({imageCache, lastStrokeTime: lastStroke.time});
    }
    
  }, []); 

  function prepareStrokes (more = {}) {

    let actualUserStrokes = userStrokesRef.current;

    const allStrokes = Object.values(actualUserStrokes).flat();
    const combinedStrokes = allStrokes.filter(stroke => !stroke.cancelled).sort((a, b) => a.time - b.time);

    if (gameInfoRef.current?.mode === 'line') {
      const backgrounds = combinedStrokes.filter(stroke=>stroke.type === 'background');
      if (isDrawingRef.current) {return backgrounds}
      else {return combinedStrokes.length ? [...backgrounds, combinedStrokes.pop()] : backgrounds;}
    }

    const lastClearIndex = combinedStrokes.map(stroke => stroke.type).lastIndexOf('clear');
    const strokesToRender = combinedStrokes.slice(lastClearIndex + 1);

    const lastCacheIndex = strokesToRender.map(stroke => {
      const cachedData = imageCache.current.get(stroke.time);
      if (cachedData) { return true } else { return false; }
    }).lastIndexOf(true);

    const needToRender = strokesToRender.slice(Math.max(lastCacheIndex, 0));
    return needToRender;
    

  }

  function showFIll ({canvas, stroke, needToRender, i}) {

        try {
          prepareCache(needToRender.slice(0, i+1))
          showZoomed({
            stroke,
            canvas, 
            zoomFactor,
            imageCache,
          })
        } catch (e) {
          console.error(e);
        }
    
  }

  function prepareCache (strokes) {

    const fillTempCanvas = document.createElement('canvas');
    fillTempCanvas.width = canvasDimensionsRef.current.width;
    fillTempCanvas.height = canvasDimensionsRef.current.height;
    const fillTempContext = fillTempCanvas.getContext('2d');

    fillTempContext.save();
    fillTempContext.setTransform(1, 0, 0, 1, 0, 0); // Сбрасываем трансформацию
    fillTempContext.clearRect(0, 0, fillTempCanvas.width, fillTempCanvas.height); // Очищаем канвас
    fillTempContext.fillStyle = "white";
    fillTempContext.fillRect(0, 0, fillTempCanvas.width, fillTempCanvas.height);
    fillTempContext.restore();
  
    // Рендерим штрихи с учетом масштаба и смещения
    strokes.forEach(stroke => {

      if (imageCache.current.get(stroke.time)) {

        showZoomed({
          stroke,
          canvas: fillTempCanvas, 
          startX: stroke.x, 
          startY: stroke.y, 
          zoomFactor: 1,
          panOffset: {x: 0, y: 0},
          imageCache,
        })

      } else if (stroke.type === 'background') {
        drawBackground({ context: fillTempContext, color: stroke.color })
      } else if (stroke.type === 'fill') {

        createFill({
          stroke,
          canvas: fillTempCanvas, 
          imageCache,
        })

      } else if (stroke.type === 'stroke') {

        drawStroke(stroke, fillTempContext);

      }
    });

    const lastStroke = strokes[strokes.length - 1];
    let imageData = imageCache.current.get(lastStroke.time);

    if (!imageData) {
      imageData = fillTempContext.getImageData(0, 0, fillTempCanvas.width, fillTempCanvas.height);
      imageCache.current.set(lastStroke.time, imageData)
    }

    return {
      lastStroke,
      imageData,
    }

  };

  function render () {

    try {

      const needToRender = prepareStrokes({render: true});
      if (!needToRender[0]) { return { empty: true }; }

      const { lastStroke, imageData } = prepareCache(needToRender);
      const imagePng = imageDataToPNG(imageData);
      return {
        lastStroke,
        imagePng,
      }
    } catch (error) {
      return { error: {
        message: error.message,
        stack: error.stack,
      },}
    }
  
    
  }

  useEffect(() => {
    redrawCanvas();
  }, [redrawer, strokesAreLoaded]); 


  useEffect(() => {
    setVisibleLineWidth(lineWidth * zoomFactor * canvasScaleRef.current);
  }, [lineWidth, zoomFactor]);


  return {
    drawStroke,
    redrawCanvas,
    render,
  }
};

