import React, { useEffect, useState } from 'react';

const HintPopup = ({
  message,
  isVisible,
  onClose,
  duration = 2500,
  top = '375px',
  left = '50%',
  width = '300px',
  id = 'hint',
  ignoreCloseIds = ['hint'] // Массив идентификаторов элементов, которые не должны вызывать закрытие
}) => {
  const [isDisplayed, setIsDisplayed] = useState(isVisible);

  useEffect(() => {
    let timeoutId;

    if (isVisible) {
      setIsDisplayed(true);
      timeoutId = setTimeout(() => {
        onClose();  // Автоматическое закрытие попапа
      }, duration);
    } else {
      timeoutId = setTimeout(() => {
        setIsDisplayed(false);  // Скрытие попапа
      }, 300); // Длительность анимации исчезновения
    }

    return () => clearTimeout(timeoutId);
  }, [isVisible, onClose, duration]);

  useEffect(() => {

    const handleDocumentClick = (event) => {
      // Выполнить проверку только если ignoreCloseIds не пустой
      if (ignoreCloseIds.length > 0 && event.target.closest(`#${ignoreCloseIds.join(', #')}`)) {
        return; // Если клик был на элементе, который нужно игнорировать, не делать ничего
      }
      onClose();
    };

    if (isDisplayed) {
      document.addEventListener('mousedown', handleDocumentClick);
      return () => {
        document.removeEventListener('mousedown', handleDocumentClick);
      };
    }
  }, [onClose, isDisplayed, ignoreCloseIds]);

  if (!isDisplayed) return null;

  return (
    <div id = {id}
      style={{
        position: 'fixed',
        top: top,
        left: left,
        transform: 'translate(-50%, -50%)',
        minWidth: width,
        padding: '7px 7px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        textAlign: 'center',
        borderRadius: '17px',
        zIndex: 1500,
        cursor: 'pointer',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        userSelect: 'none',
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out',
      }}
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
    >
      {message}
    </div>
  );
};

export default HintPopup;
