// WordChangeOverlay.js

import React from 'react';
import { FaCog, FaExpand } from 'react-icons/fa';

import './WordChangeOverlay.css'; // Подключаем стили
import { useTranslation } from 'react-i18next';

function WordChangeOverlay({
  gameContext,
}) {

  const { t } = useTranslation();

  const {
    gameInfoRef,
    setShowWordSettings,
    setShowSettings,

    changeWord,
    activateHints,
    openLetter,

    delayPremiumMenu,
    userSetsRef,
    premiumFeaturesRef,
  } = gameContext;

  const game = gameInfoRef.current;
  const hintsEnabled = game.hint?.active;
  const hints = game.hint?.hints || [];

  const [changingWord, setChangingWord] = React.useState(false);

  const activateHintsFun = ()=>{

    if (
      !premiumFeaturesRef.current.includes('hints')
      || userSetsRef.current.premiumOff
    ) {
      setShowWordSettings(false);
      delayPremiumMenu('menu');
    } else {
      activateHints();
    }

  }

  const handleFullScreen = () =>{

    const popupParams = {
      title: t('fullscreen.title'),
      message: t('fullscreen.text'),
      buttons: [
        { id: 'yes', type: 'default', text: t('fullscreen.button_confirm') },
        { id: 'no', type: 'cancel', text: t('fullscreen.button_cancel') },
      ]
    };

    try {
      window.Telegram.WebApp.showPopup(popupParams, (buttonId) => {
        if (buttonId === 'yes') {
          makeItFullScreen()
        }
    });
    } catch (e) {
      makeItFullScreen()
    }


  }



  const makeItFullScreen = () =>{

    window.open(window.location.href, '_blank');
    setTimeout(() => {
      window.Telegram.WebApp.close();
    }, 100); 

    // () => window.Telegram.WebApp.openTelegramLink('http://t.me/CrocoDraw_Bot/open')
    // () => window.Telegram.WebApp.openLink('http://t.me/CrocoDraw_Bot/open')
    // () => window.Telegram.WebApp.openLink(window.location.href)
  }

  const showSettingsFun = ()=>{
    setShowWordSettings(false);
    setShowSettings(true);
  }

  const definition = game.definitions?.[game.word] || ' ';
  const [showFullDefinition, setShowFullDefinition] = React.useState(false);
  const toggleDefinition = () => {
    setShowFullDefinition(!showFullDefinition);
  }

  const renderDefinition = () => {
    if (!definition) return null;
    
    if (definition.length > 140 && !showFullDefinition) {
      return (
        <div className="definition-display">
          {definition.substr(0, 120).replace(/\s+\S*$/, "")}
          <span>... </span>
          <button className="inline-more-button" onClick={toggleDefinition}>{t('word.button_definition_more')}</button>
        </div>
      );
    } else if (definition.length > 400) {
      return (
        <div className="definition-display">
          {definition.substr(0, 400).replace(/\s+\S*$/, "")}
          <span>... </span>
        </div>
      );
    } else {
      return (
        <div className="definition-display">
          {definition}
        </div>
      );
    }
  };


  function wordAndHints () {

    return(
      <>
        {changingWord ? (
          <div className="button-row">
            <button className="small-button" onClick={() => changeWord('previous')}>
              {t('word.button_previous')}
            </button>
            <button className="small-button" onClick={() => changeWord('next')}>
              {t('word.button_new')}
            </button>
          </div>
        ) : (
          <button className="wide-button" onClick={()=>{setChangingWord(true)}}>
            {t('word.button_change_word')}
          </button>
        )}

        {!hintsEnabled ? (
          <button className="wide-button" onClick={activateHintsFun}>
            {t('word.button_hints')}
          </button>
        ) : (
          <div className="hints-container">
            {hints.map((hint, num) => (
              <button 
                key={num} 
                className={hint.opened ? "hint-active-button" : "hint-button"}
                onClick={()=>{openLetter(num)}}
                >
                {hint.letter}
              </button>
            ))}
          </div>
        )}
      </>
    )
    
  }

  return (
    <>
      <div className="overlay-background" onClick={()=>{
        setShowWordSettings(false); 
        setChangingWord(false);
        setShowFullDefinition(false);
        }}></div>
      <div className="word-menu">
        <div className="word-display">{game.word}</div>
        {renderDefinition()}

        {gameInfoRef.current.mode !== 'sprint' && (
          wordAndHints()
        )}

        {}

        <button className="wide-button" onClick={handleFullScreen}>
          <FaExpand style={{ marginRight: '8px', marginBottom: '3px', verticalAlign: 'middle'}} />
          {t('fullscreen.button_start')}
        </button>

        <button className="gray-button" onClick={showSettingsFun}>
          <FaCog style={{ marginRight: '8px', marginBottom: '3px', verticalAlign: 'middle'}} />
          {t('settings.title')}
        </button>

      </div>
    </>
  );
}

export default WordChangeOverlay;
